import { Stack, styled } from '@mui/material';
import { FC } from 'react';

import { Typography } from '../../../../Typography';

export interface ContentBlock {
  title: string;
  text: string;
}

export interface InsightsTemplateProps {
  contentBlocks: ContentBlock[];
}

const Container = styled(Stack)({
  flexDirection: 'column',
  maxHeight: '804px',
  overflowY: 'auto',
});

const ContentContainer = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing.xxxlarge, // 40px between content blocks
}));

const BlockContainer = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing.medium, // 16px between block title and text
}));

export const InsightsTemplate: FC<InsightsTemplateProps> = ({ contentBlocks }) => {
  return (
    <Container>
      <ContentContainer>
        {contentBlocks.map((block, index) => (
          <BlockContainer key={index}>
            <Typography variant="heading.H7" lineClamp={1}>
              {block.title}
            </Typography>
            <Typography variant="heading.H8" sx={{ whiteSpace: 'pre-wrap' }}>
              {block.text}
            </Typography>
          </BlockContainer>
        ))}
      </ContentContainer>
    </Container>
  );
};
