import { useCallback, useEffect, useRef, useState } from 'react';

import { useAilyAgent } from '../providers';
import { useAilyAgentPlaybackStatus } from './useAilyAgentPlaybackStatus';

/**
 * Provides playback control and progress tracking for the AilyAgent audio system.
 */
export function useAilyAgentPlaybackControls() {
  const { agent } = useAilyAgent();
  const status = useAilyAgentPlaybackStatus();
  const [playbackProgress, setPlaybackProgress] = useState(agent.playbackProgress);
  const animationFrameRef = useRef<number | null>(null);
  const lastProgressRef = useRef(0);

  /**
   * Updates progress using requestAnimationFrame loop.
   * Uses throttling to avoid frequent updates.
   */
  const updateProgress = useCallback(() => {
    const progress = agent.playbackProgress;

    if (Math.abs(progress - lastProgressRef.current) >= 0.02) {
      lastProgressRef.current = progress;
      setPlaybackProgress(progress);
    }

    // Continue updating if still playing
    if (agent.status === 'playing') {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    }
  }, [agent]);

  /**
   * Starts requestAnimationFrame loop.
   */
  const startTracking = useCallback(() => {
    if (animationFrameRef.current === null) {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    }
  }, [updateProgress]);

  /**
   * Cancels any running animation frame loop.
   */
  const stopTracking = useCallback(() => {
    if (animationFrameRef.current !== null) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }
  }, []);

  /**
   * Toggles between resume and pause based on agent state.
   */
  const togglePlayback = useCallback(() => {
    if (!agent.hasPlayableOperation) return;

    if (agent.status === 'playing') {
      agent.pause();
      stopTracking();
    } else {
      agent.resume();
      startTracking();
    }
  }, [agent, startTracking, stopTracking]);

  /**
   * Watch agent status changes and auto-start/stop progress tracking.
   */
  useEffect(() => {
    if (status === 'playing') {
      startTracking();
    } else {
      stopTracking();
    }

    if (status === 'ended') {
      setPlaybackProgress(0);
    }
  }, [status, startTracking, stopTracking]);

  /**
   * Cleanup on unmount.
   */
  useEffect(() => {
    return () => {
      stopTracking();
    };
  }, [stopTracking]);

  return {
    status,
    playbackProgress,
    togglePlayback,
  };
}
