import { FooterControls } from '@aily/ui-components';
import React, { FC } from 'react';

import { useSlideshow } from '../../../../components';
import { useAilyAgentPlaybackControls } from '../../hooks';

const SlideshowFooterControls: FC = () => {
  const { state, dispatch } = useSlideshow();
  const { status, playbackProgress, togglePlayback } = useAilyAgentPlaybackControls();
  const isPlaying = status === 'playing';

  const handlePrevButtonClick = () => {
    dispatch({ type: 'PREV_SLIDE' });
  };

  const handleNextButtonClick = () => {
    dispatch({ type: 'NEXT_SLIDE' });
  };

  return (
    <FooterControls
      isPlaying={isPlaying}
      playbackProgress={playbackProgress / 100}
      onPlayButtonClick={togglePlayback}
      progressTransition={{ duration: 0, ease: 'linear' }}
      prevButtonDisabled={!state.hasPrevSlide}
      nextButtonDisabled={!state.hasNextSlide}
      onPrevButtonClick={handlePrevButtonClick}
      onNextButtonClick={handleNextButtonClick}
    />
  );
};

export default SlideshowFooterControls;
