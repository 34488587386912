import { FC, useId } from 'react';

import DropShadowFilter from './DropShadowFilter';

export interface FilledCircleSymbolProps {
  size: number;
  strokeWidth: number;
}

export const FilledCircleSymbol: FC<FilledCircleSymbolProps> = ({ size = 12, strokeWidth = 2 }) => {
  const id = useId();
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="currentColor"
      style={{ overflow: 'visible' }}
    >
      <DropShadowFilter id={id} size={size} />
      <g filter={`url(#${id})`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - strokeWidth / 2}
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

export default FilledCircleSymbol;
