import { EventTrackingConfig, withEventTracking } from '@aily/analytics-service';
import { useGetPageContentQuery } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { FilterRowButtonType, LoadingSkeleton } from '@aily/saas-core';
import { Alert } from '@mui/material';
import { find, isEqual } from 'lodash-es';
import React, { useCallback, useEffect } from 'react';

import { ClosableModal, ResponsiveScaleContainer } from '../../../../../components';
import { useProFiltersContext } from '../../../../../contexts';
import { useModule } from '../../../../../providers';
import { parseCaption } from '../../../utils/parseCaption';
import { AgentLayout } from '../../AgentLayout/AgentLayout';
import {
  AgentStructureContextProvider,
  useAgentStructureContext,
} from '../contexts/AgentStructureContext';

const AGENT_PATH = '/web/pro/agent';

const contentBackground = 'linear-gradient(185deg, #31D4B6 0%, #114F44 5%, #111521 10%, #000 15%)';

interface AgentModalProps {
  story?: T.Story;
  open: boolean;
  parent?: string;
  pageId?: string;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onClose: () => void;
  onDecide?: () => void;
  onSeen?: (story?: T.Story, parent?: string | number, filters?: FilterRowButtonType[]) => void;
  onDismiss?: (story?: T.Story, parent?: string | number, filters?: FilterRowButtonType[]) => void;
}

export const AgentModalContainer: React.FC<AgentModalProps> = ({
  story,
  open,
  parent,
  pageId: propPageId,
  onBackdropClose,
  onClose,
  onDecide,
  onSeen,
  onDismiss,
}) => {
  const module = useModule();
  const foundPageId = find(module?.pages, { absolutePath: AGENT_PATH })?.id;
  const pageId = foundPageId || propPageId || '';

  const { data, loading, error } = useGetPageContentQuery({
    variables: { pageId },
    skip: !pageId,
  });

  if (loading) return <LoadingSkeleton />;

  if (error) {
    return (
      <Alert variant="outlined" severity="error">
        {error.graphQLErrors[0]?.message ?? error.message}
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert variant="outlined" severity="error">
        No data
      </Alert>
    );
  }

  return (
    <AgentStructureContextProvider
      componentId={data.pageContent.body[0].id}
      agentId={story?.id ?? ''}
    >
      <AgentModal
        story={story}
        open={open}
        parent={parent}
        pageId={pageId}
        onBackdropClose={onBackdropClose}
        onClose={onClose}
        onDecide={onDecide}
        onSeen={onSeen}
        onDismiss={onDismiss}
      />
    </AgentStructureContextProvider>
  );
};

const AgentModal: React.FC<AgentModalProps> = React.memo(
  ({ story, open, parent, onBackdropClose, onClose, onDecide, onSeen, onDismiss }) => {
    const { selectedFilters } = useProFiltersContext() ?? {};
    const { isAgentStructureLoading, agentStructure } = useAgentStructureContext();

    const handleClose = useCallback(() => {
      onDismiss?.(story, parent, selectedFilters);
      onClose();
    }, [onDismiss, onClose, story, parent, selectedFilters]);

    useEffect(() => {
      if (open) {
        onSeen?.(story, parent, selectedFilters);
      }
    }, [open, story, parent, selectedFilters, onSeen]);

    if (isAgentStructureLoading && !agentStructure) return null;

    return (
      <ClosableModal
        ContentBackgroundProps={{ sx: { background: contentBackground } }}
        CloseButtonProps={{ sx: { top: 10, fontSize: '28px' } }}
        open={open}
      >
        <ResponsiveScaleContainer containerWidth={1250} containerHeight={834}>
          {!isAgentStructureLoading && agentStructure && (
            <AgentLayout
              onAgentClose={handleClose}
              onBackdropClose={onBackdropClose}
              onDecide={onDecide}
            />
          )}
        </ResponsiveScaleContainer>
      </ClosableModal>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const trackingConfig: EventTrackingConfig<AgentModalProps> = {
  onSeen: {
    eventName: 'agent.seen',
    getEventProps: (story, parent, filters) => {
      const caption = parseCaption(story?.caption || '{}');
      const name =
        typeof caption === 'object' ? (caption.headline ?? caption.opt_agent?.name) : caption;

      return {
        screen_id: parent?.toString() || '',
        name,
        component: 'agent',
        component_id: story?.id?.toString() ?? '',
        component_type: 'agent',
        intent: 'see',
        item_type: 'agent',
        event_version: '2.1.0',
        filter_name: filters?.map((f) => f.label),
      };
    },
  },
  onDismiss: {
    eventName: 'agent.closed',
    getEventProps: (story, parent, filters) => {
      const caption = parseCaption(story?.caption || '{}');
      const name =
        typeof caption === 'object' ? (caption.headline ?? caption.opt_agent?.name) : caption;

      return {
        screen_id: parent?.toString() || '',
        name,
        component: 'agent',
        component_id: story?.id?.toString() ?? '',
        component_type: 'agent',
        intent: 'close',
        item_type: 'button',
        event_version: '2.1.0',
        filter_name: filters?.map((f) => f.label),
      };
    },
  },
};

export const TrackedAgentModalContent = withEventTracking(AgentModal, trackingConfig);
