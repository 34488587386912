import { FC, useId } from 'react';

import DropShadowFilter from './DropShadowFilter';

export interface OutlinedCircleSymbolProps {
  size: number;
  strokeWidth: number;
  fill?: string;
}

export const OutlinedCircleSymbol: FC<OutlinedCircleSymbolProps> = ({
  size = 12,
  strokeWidth = 2,
  fill = 'none',
}) => {
  const id = useId();
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill={fill}
      style={{ overflow: 'visible' }}
    >
      <DropShadowFilter id={id} size={size} />
      <g filter={`url(#${id})`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - strokeWidth / 2}
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

export default OutlinedCircleSymbol;
