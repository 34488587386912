import {
  AgentSentiment,
  IProPayloadTemplateResult,
  ProPayloadTemplateDecisionResult,
} from '@aily/graphql-sdk/schema';
import {
  AgentHeader,
  CrossLinkButton,
  DecisionTemplate as DecisionTemplateDS,
  Typography,
} from '@aily/ui-components';
import { DecisionTemplateSentiment } from '@aily/ui-components/src/components/agent/commons/card/DecisionTemplate/DecisionTemplate';
import { ColorTokenKey } from '@aily/ui-theme';
import { FC, useCallback } from 'react';

import { useAilyAgent } from '../providers';

export interface DecisionTemplateProps {
  data: ProPayloadTemplateDecisionResult;
  popups?: IProPayloadTemplateResult[];
  onAgentClose?: () => void;
  isTitleComponent?: boolean;
}

interface HeaderPopupsProps {
  popups?: IProPayloadTemplateResult[];
}

const HeaderPopups: FC<HeaderPopupsProps> = ({ popups }) => {
  const { setIsPopupOpen, setPopupData } = useAilyAgent();

  const handlePopupOpen = useCallback(
    (popup: IProPayloadTemplateResult[]) => {
      setIsPopupOpen(true);
      setPopupData(popup);
    },
    [setIsPopupOpen, setPopupData],
  );

  return (
    <AgentHeader.Buttons>
      {popups?.map((popup) => (
        // we don't have a way of really knowing what the prefix and title are from the data we get
        <CrossLinkButton
          key={popup.id}
          prefix={popup.moduleIds?.[0] ?? ''}
          onClick={() => handlePopupOpen([popup])}
        >
          {popup.title}
        </CrossLinkButton>
      ))}
    </AgentHeader.Buttons>
  );
};

// this is incorrect, data needs to send us a different type, without neutral and decision option here
const sentimentColorMap: Record<AgentSentiment, ColorTokenKey> = {
  [AgentSentiment.Positive]: 'sentiment.positive',
  [AgentSentiment.Neutral]: 'sentiment.positive',
  [AgentSentiment.Decision]: 'sentiment.positive',
  [AgentSentiment.Negative]: 'sentiment.negative',
};

const getTitle = (data: ProPayloadTemplateDecisionResult) => {
  return (
    <>
      {data.noHighlightText1}{' '}
      <Typography variant="inherit" color={sentimentColorMap[data.highlightedText.sentiment]}>
        {data.highlightedText.text}
      </Typography>{' '}
      {data.noHighlightText2}
      {data.highlightedTextLast && (
        <Typography variant="inherit" color={sentimentColorMap[data.highlightedTextLast.sentiment]}>
          {data.highlightedTextLast.text}
        </Typography>
      )}
    </>
  );
};

const DecisionTemplate: FC<DecisionTemplateProps> = ({
  data,
  popups,
  onAgentClose,
  isTitleComponent,
}) => {
  // decision template on the summarizer agent is used to render the header, on other agent types, it's a card template
  if (isTitleComponent) {
    return (
      <AgentHeader
        variant="two-line"
        title={getTitle(data)}
        buttons={<HeaderPopups popups={popups} />}
        onCloseButtonClick={onAgentClose}
      />
    );
  }

  return (
    <DecisionTemplateDS
      title={data.noHighlightText1}
      primaryValue={{
        value: data.highlightedText.text,
        sentiment: data.highlightedText.sentiment.toLowerCase() as DecisionTemplateSentiment,
      }}
      // data mismatch here. This value should never be sentiment colorable
      primaryValueSubtitle={{
        primaryText: '',
        sentiment: data.highlightedText.sentiment.toLowerCase() as DecisionTemplateSentiment,
        secondaryText: data.noHighlightText2,
      }}
      secondaryValue={{
        value: data.highlightedTextLast?.text ?? '',
        sentiment: data.highlightedTextLast?.sentiment.toLowerCase() as DecisionTemplateSentiment,
      }}
    />
  );
};

export default DecisionTemplate;
