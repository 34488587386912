import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { map } from 'lodash-es';
import React from 'react';

import { ContentRowIcon, type ContentRowIconProps } from './internal/ContentRowIcon';

export interface SummaryTemplateProps {
  rows: ContentRowIconProps[];
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xlarge,
  maxHeight: '100%',
  overflowY: 'auto',
}));

export const SummaryTemplate: React.FC<SummaryTemplateProps> = ({ rows }) => {
  return (
    <Container>
      {map(rows, (row, index) => (
        <ContentRowIcon
          key={index}
          iconName={row.iconName}
          title={row.title}
          description={row.description}
        />
      ))}
    </Container>
  );
};
