import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface SliderCarouselProviderProps {
  children: ReactNode;
  totalSlides: number;
  index?: number;
  defaultIndex?: number;
  onIndexChange?: (index: number) => void;
  dragThreshold?: number;
}

export interface SliderCarouselContextValue {
  currentIndex: number;
  totalSlides: number;
  goToIndex: (index: number) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  handleDragStart: (startX: number) => void;
  handleDragMove: (currentX: number) => void;
  handleDragEnd: () => void;
  isGrabbing: boolean;
  setIsGrabbing: (v: boolean) => void;
}

const SliderCarouselContext = createContext<SliderCarouselContextValue | null>(null);

export const SliderCarouselProvider: React.FC<SliderCarouselProviderProps> = ({
  children,
  totalSlides,
  index,
  defaultIndex = 0,
  onIndexChange,
  dragThreshold = 40,
}) => {
  const isControlled = index !== undefined;
  const [uncontrolledIndex, setUncontrolledIndex] = useState(defaultIndex);
  const currentIndex = isControlled ? index : uncontrolledIndex;

  const containerRef = useRef<HTMLDivElement>(null);

  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [dragDelta, setDragDelta] = useState(0);

  const getSlideWidth = () => containerRef.current?.offsetWidth ?? 0;

  const scrollToIndex = useCallback((i: number) => {
    const slideWidth = getSlideWidth();
    if (containerRef.current) {
      containerRef.current.style.transition = 'transform 0.3s ease';
      containerRef.current.style.transform = `translateX(-${i * slideWidth}px)`;
    }
  }, []);

  const goToIndex = useCallback(
    (i: number) => {
      const clamped = Math.max(0, Math.min(totalSlides - 1, i));

      if (!isControlled) setUncontrolledIndex(clamped);
      onIndexChange?.(clamped);
      scrollToIndex(clamped);
    },
    [isControlled, onIndexChange, scrollToIndex, totalSlides],
  );

  useEffect(() => {
    scrollToIndex(currentIndex);
  }, [currentIndex, scrollToIndex]);

  const handleDragStart = (x: number) => {
    setIsGrabbing(true);
    setStartX(x);
    setDragDelta(0);
  };

  const handleDragMove = (x: number) => {
    if (!isGrabbing || !containerRef.current) return;

    const delta = x - startX;
    const slideWidth = getSlideWidth();
    const maxDelta = currentIndex * slideWidth;
    const minDelta = -(totalSlides - currentIndex - 1) * slideWidth;
    const clampedDelta = Math.max(minDelta, Math.min(maxDelta, delta));

    containerRef.current.style.transition = 'none';
    containerRef.current.style.transform = `translateX(${-currentIndex * slideWidth + clampedDelta}px)`;

    setDragDelta(clampedDelta);
  };

  const handleDragEnd = () => {
    if (!isGrabbing) return;
    setIsGrabbing(false);

    if (Math.abs(dragDelta) > dragThreshold) {
      goToIndex(currentIndex + (dragDelta < 0 ? 1 : -1));
    } else {
      scrollToIndex(currentIndex);
    }
  };

  return (
    <SliderCarouselContext.Provider
      value={{
        currentIndex,
        totalSlides,
        goToIndex,
        containerRef,
        handleDragStart,
        handleDragMove,
        handleDragEnd,
        isGrabbing,
        setIsGrabbing,
      }}
    >
      {children}
    </SliderCarouselContext.Provider>
  );
};

export const useSliderCarousel = () => {
  const context = useContext(SliderCarouselContext);

  if (!context) {
    throw new Error('SliderCarousel components must be used within SliderCarouselProvider');
  }

  return context;
};
