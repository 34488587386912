import { alpha, styled } from '@mui/material/styles';
import { FC } from 'react';

import { Typography } from '../../../Typography';

export interface AgentIntroProps {
  children: string;
}

const Container = styled('div')(({ theme }) => ({
  width: 891, // Fixed by design
  borderRadius: theme.tokens.spacing['large'],
  padding: theme.tokens.spacing['xlarge'],
  backgroundColor: alpha(theme.tokens.color['background.light'], 0.5),
}));

const AgentIntro: FC<AgentIntroProps> = ({ children }) => (
  <Container>
    <Typography variant="heading.H6" align="center">
      {children}
    </Typography>
  </Container>
);

export default AgentIntro;
