import { AudioLoader } from './AudioLoader';
import { AudioVisualizer } from './AudioVisualizer';
import { SVGAnimator } from './SVGAnimator';

export interface AilyAgentOperation {
  audioPath: string;
  elementRef: HTMLElement;
  delayBeforeStart?: number;
  delayAfterEnd?: number;
  corner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export type AilyAgentStatus = 'idle' | 'loading' | 'playing' | 'paused' | 'ended';
export type AilyAgentEvent = 'play' | 'pause' | 'end' | 'step';

/**
 * Payload emitted with each AilyAgentEvent.
 */
export interface AilyAgentEventData {
  index: number | null;
  operation: AilyAgentOperation | null;
}

export class AilyAgent {
  private static instance: AilyAgent | null = null;

  private readonly audioContext?: AudioContext;
  private readonly audioLoader?: AudioLoader;
  private readonly audioVisualizer?: AudioVisualizer;
  private svgAnimator?: SVGAnimator;

  private operations: AilyAgentOperation[] = [];
  private currentIndex: number = 0;

  private audioSource?: AudioBufferSourceNode;
  private audioStartTime: number = 0;
  private audioPausedAt: number = 0;
  private buffer?: AudioBuffer;

  private idleTimeout?: ReturnType<typeof setTimeout>;
  private readonly idleTimeoutDuration: number = 3000;

  private isLoadingAudio: boolean = false;
  private isPaused: boolean = false;

  private activeTimeouts: Set<ReturnType<typeof setTimeout>> = new Set(); // Track all timeouts
  private runId: number = 0; // Used to ignore outdated timeouts after exit()

  private listeners: Record<AilyAgentEvent, Set<(data: AilyAgentEventData) => void>> = {
    play: new Set(),
    pause: new Set(),
    end: new Set(),
    step: new Set(),
  };

  private constructor() {
    this.audioContext = this.createAudioContext();
    if (this.audioContext) {
      this.audioLoader = new AudioLoader(this.audioContext);
      this.audioVisualizer = new AudioVisualizer(this.audioContext);
    }
  }

  /**
   * Returns the singleton instance of AilyAgent.
   */
  public static getInstance(): AilyAgent {
    if (!AilyAgent.instance) {
      AilyAgent.instance = new AilyAgent();
    }

    return AilyAgent.instance;
  }

  /**
   * Creates an AudioContext instance if supported.
   */
  private createAudioContext(): AudioContext | undefined {
    if (window.AudioContext) {
      return new AudioContext();
    }

    console.warn('AudioContext is not supported in this browser.');
    return undefined;
  }

  /**
   * Adds a listener for the given audio event.
   */
  public addEventListener(
    event: AilyAgentEvent,
    callback: (data: AilyAgentEventData) => void,
  ): void {
    this.listeners[event].add(callback);
  }

  /**
   * Removes a previously added event listener.
   */
  public removeEventListener(
    event: AilyAgentEvent,
    callback: (data: AilyAgentEventData) => void,
  ): void {
    this.listeners[event].delete(callback);
  }

  /**
   * Emits a lifecycle event to all registered listeners.
   */
  private emit(event: AilyAgentEvent): void {
    const data: AilyAgentEventData = {
      index: this.currentIndex < this.operations.length ? this.currentIndex : null,
      operation:
        this.currentIndex < this.operations.length ? this.operations[this.currentIndex] : null,
    };

    this.listeners[event].forEach((callback) => callback(data));
  }

  /**
   * Sets the main SVG element and connects the visualizer.
   */
  public setSvgElement(svgElement: SVGSVGElement, selector: string): void {
    this.svgAnimator = new SVGAnimator(svgElement);

    const svgEllipse = svgElement.querySelector<SVGElement>(selector);
    if (svgEllipse) {
      this.audioVisualizer?.setSvgElement(svgEllipse);
    }
  }

  /**
   * Resets the agent with new operations and starts playback from the beginning.
   */
  public resetAndStart(operations: AilyAgentOperation[]): void {
    const wasPaused = this.isPaused;
    this.exit();
    this.isPaused = wasPaused;
    this.setOperations(operations);
    this.start();
  }

  /**
   * Sets the operations queue to be played by the agent.
   */
  public setOperations(operations: AilyAgentOperation[]): void {
    this.operations = operations;
  }

  /**
   * Starts the agent playback sequence from the beginning.
   */
  public start(): void {
    if (this.operations.length === 0) return;

    this.currentIndex = 0;
    this.runId++; // Invalidate all previous async callbacks

    if (this.isPaused) return; // Stay paused and don't show the wisp

    this.svgAnimator?.enter(); // Only enter if we're not paused
    this.processNextOperation(this.runId);
  }

  /**
   * Pauses current playback and stores resume offset.
   */
  public pause(): void {
    if (this.audioSource && this.audioContext && this.buffer) {
      this.audioPausedAt = this.audioContext.currentTime - this.audioStartTime;
      this.isPaused = true;

      this.stopAndDisconnectSource();

      this.svgAnimator?.exit(); // Hide the wisp while paused

      this.emit('pause');
    }
  }

  /**
   * Resumes playback from where it was paused,
   * or restarts from the beginning if all operations have ended.
   */
  public resume(): void {
    if (this.status === 'ended' && this.operations.length > 0) {
      // Restart the sequence from the beginning
      this.isPaused = false;
      this.currentIndex = 0;
      this.runId++; // Invalidate old callbacks
      this.svgAnimator?.enter();
      this.processNextOperation(this.runId);
      return;
    }

    if (!this.buffer || this.audioPausedAt <= 0) {
      if ((this.status === 'idle' || this.status === 'ended') && this.operations.length > 0) {
        this.isPaused = false;
        this.start(); // Start from beginning
      }
      return;
    }

    const source = this.audioContext!.createBufferSource();
    source.buffer = this.buffer;

    const resumeOffset = this.audioPausedAt;
    this.audioStartTime = this.audioContext!.currentTime - resumeOffset;
    this.audioPausedAt = 0;
    this.isPaused = false;

    this.svgAnimator?.enter(); // Re-enter the wisp on resume

    source.onended = () => {
      if (this.isPaused) return;
      this.emit('end');
      this.handleOperationEnded(this.operations[this.currentIndex], this.runId);
    };

    this.audioSource = source;
    this.audioVisualizer?.connectSource(source);
    source.start(0, resumeOffset);

    this.emit('play');
  }

  /**
   * Immediately stops everything and resets the agent state.
   */
  public exit(): void {
    this.runId++; // Invalidate all scheduled timeouts & callbacks

    for (const timeout of this.activeTimeouts) {
      clearTimeout(timeout);
    }

    this.activeTimeouts.clear();

    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = undefined;
    }

    this.stopAndDisconnectSource();
    this.buffer = undefined;
    this.audioPausedAt = 0;
    this.audioStartTime = 0;
    this.operations = [];
    this.currentIndex = 0;

    this.svgAnimator?.exit();

    this.emit('end');
  }

  /**
   * Whether the agent has any operations to play.
   */
  public get hasPlayableOperation(): boolean {
    return this.operations.length > 0;
  }

  /**
   * Returns the current status of the agent.
   */
  public get status(): AilyAgentStatus {
    if (this.isLoadingAudio) return 'loading';
    if (this.audioContext?.state === 'running' && this.audioSource && this.audioPausedAt === 0)
      return 'playing';
    if (this.audioContext?.state === 'suspended' && this.audioPausedAt > 0) return 'paused';
    if (!this.audioSource && this.currentIndex >= this.operations.length) return 'ended';

    return 'idle';
  }

  /**
   * Returns current audio playback progress as a percentage (0–100).
   */
  public get playbackProgress(): number {
    if (!this.audioContext || !this.buffer) return 0;

    const now = this.audioContext.currentTime;
    const elapsed = this.audioPausedAt > 0 ? this.audioPausedAt : now - this.audioStartTime;

    return Math.min((elapsed / this.buffer.duration) * 100, 100);
  }

  /**
   * Advances to the next operation in the queue.
   */
  private processNextOperation(runId: number): void {
    if (this.currentIndex < this.operations.length) {
      const operation = this.operations[this.currentIndex];

      const timeout = setTimeout(() => {
        this.activeTimeouts.delete(timeout);
        if (runId !== this.runId) return;

        this.emit('step'); // Emit step before playback starts
        this.play(operation, runId);
      }, operation.delayBeforeStart || 0);

      this.activeTimeouts.add(timeout);
    } else {
      this.idleTimeout = setTimeout(() => {
        if (runId === this.runId) this.exit();
      }, this.idleTimeoutDuration);

      this.activeTimeouts.add(this.idleTimeout);
    }
  }

  /**
   * Starts audio playback for a given operation.
   */
  private play(operation: AilyAgentOperation, runId: number): void {
    this.cleanupAudioSource();
    this.isLoadingAudio = true;

    this.svgAnimator?.moveToElement(operation.elementRef, operation.corner);

    this.audioLoader?.load(operation.audioPath, (buffer) => {
      if (runId !== this.runId) return;
      this.handleLoadedBuffer(buffer, operation, runId);
    });
  }

  /**
   * Handles loaded buffer and starts playback, or skips on failure.
   */
  private handleLoadedBuffer(
    buffer: AudioBuffer | null,
    operation: AilyAgentOperation,
    runId: number,
  ): void {
    this.isLoadingAudio = false;

    if (!buffer) {
      console.warn(`Audio failed to load: ${operation.audioPath}`);
      this.emit('end');
      this.handleOperationEnded(operation, runId);
      return;
    }

    this.setupAudioSource(buffer, operation, runId);
  }

  /**
   * Sets up and starts the audio source.
   */
  private setupAudioSource(
    buffer: AudioBuffer,
    operation: AilyAgentOperation,
    runId: number,
  ): void {
    if (!this.audioContext) return;

    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;

    source.onended = () => {
      if (this.isPaused || runId !== this.runId) return;
      this.emit('end');
      this.handleOperationEnded(operation, runId);
    };

    this.buffer = buffer;
    this.audioSource = source;
    this.audioStartTime = this.audioContext.currentTime;
    this.audioPausedAt = 0;
    this.isPaused = false;

    this.audioVisualizer?.connectSource(source);
    source.start(0);

    this.emit('play');
  }

  /**
   * Ends the current operation and moves to the next.
   */
  private handleOperationEnded(operation: AilyAgentOperation, runId: number): void {
    this.cleanupAudioSource();

    const timeout = setTimeout(() => {
      this.activeTimeouts.delete(timeout);
      if (runId !== this.runId) return;

      this.currentIndex++;
      this.processNextOperation(runId);
    }, operation.delayAfterEnd || 0);

    this.activeTimeouts.add(timeout);
  }

  /**
   * Fully clears current audio playback state.
   */
  private cleanupAudioSource(): void {
    this.stopAndDisconnectSource();
    this.buffer = undefined;
    this.audioPausedAt = 0;

    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.activeTimeouts.delete(this.idleTimeout);
      this.idleTimeout = undefined;
    }

    for (const timeout of this.activeTimeouts) {
      clearTimeout(timeout);
    }

    this.activeTimeouts.clear();
  }

  /**
   * Stops and disconnects current audio node.
   */
  private stopAndDisconnectSource(): void {
    if (this.audioSource) {
      this.audioSource.stop();
      this.audioSource.disconnect();
      this.audioSource = undefined;
    }
  }
}
