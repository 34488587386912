import { GetProAgentDetailsInputDocument } from '@aily/graphql-sdk/modules/pro';
import { ProAgentStructureResult } from '@aily/graphql-sdk/schema';
import * as T from '@aily/graphql-sdk/schema';
import { useQuery } from '@aily/saas-graphql-client';
import { filter } from 'lodash-es';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useModule } from '../../../../../providers';

const query = GetProAgentDetailsInputDocument;

export type ProQueryAbstractType = { __typename?: 'Query'; pro?: T.ProModuleResult };

interface AgentStructureProviderProps {
  children: ReactNode;
  agentId: string;
  componentId: string;
}

interface AgentStructureContextValue {
  agentStructure?: ProAgentStructureResult;
  isAgentStructureLoading: boolean;
}

const AgentStructureContext = createContext<AgentStructureContextValue | undefined>(undefined);

export const AgentStructureContextProvider = (props: AgentStructureProviderProps) => {
  const { agentId, componentId, children } = props;

  const moduleId = useModule()?.id ?? '';

  const input = useMemo(
    () => ({ id: componentId, moduleId, agentId }),
    [componentId, moduleId, agentId],
  );

  const { data, loading } = useQuery<ProQueryAbstractType>(query, {
    variables: { input },
  });

  // Remove outro template
  const agentStructure = useMemo(() => {
    const structure = data?.pro?.agent?.details?.structure;
    if (!structure) return undefined;

    return {
      ...structure,
      templates: filter(structure.templates, (template) => template.templateName !== 'outro'),
    };
  }, [data?.pro?.agent?.details?.structure]);

  const isAgentStructureLoading = loading;

  const value = useMemo(() => {
    return {
      agentStructure: agentStructure ?? undefined,
      isAgentStructureLoading,
    };
  }, [agentStructure, isAgentStructureLoading]);

  return <AgentStructureContext.Provider value={value}>{children}</AgentStructureContext.Provider>;
};

export const useAgentStructureContext = () => {
  const context = useContext(AgentStructureContext);
  if (!context) {
    throw new Error('useAgentStructureContext must be used within a AgentStructureContextProvider');
  }

  return context;
};
