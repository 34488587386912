import { styled } from '@mui/material/styles';
import React from 'react';

export interface AgentCardFooterProps {
  children: React.ReactNode;
}

const Root = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  height: 54,
  flexShrink: 0,
});

const AgentCardFooter: React.FC<AgentCardFooterProps> = ({ children }) => <Root>{children}</Root>;

export default AgentCardFooter;
