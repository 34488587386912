import { Box } from '@mui/material';
import React from 'react';

import { Slideshow, SlideshowProps } from '../../../../components';

export const AgentSlideshow: React.FC<SlideshowProps> = ({ children, ...rest }) => (
  <Box position="relative">
    <Slideshow {...rest}>{children}</Slideshow>
  </Box>
);
