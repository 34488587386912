import { useEffect, useState } from 'react';

import type { AilyAgentStatus } from '../classes';
import { useAilyAgent } from '../providers';

/**
 * Hook that provides the current status of the AilyAgent ("idle", "playing", etc.).
 * It listens to play/pause/end events to remain in sync.
 */
export function useAilyAgentPlaybackStatus(): AilyAgentStatus {
  const { agent } = useAilyAgent();
  const [status, setStatus] = useState<AilyAgentStatus>(agent.status);

  useEffect(() => {
    const update = () => setStatus(agent.status);

    agent.addEventListener('play', update);
    agent.addEventListener('pause', update);
    agent.addEventListener('end', update);

    return () => {
      agent.removeEventListener('play', update);
      agent.removeEventListener('pause', update);
      agent.removeEventListener('end', update);
    };
  }, [agent]);

  return status;
}
