import { gql } from '@apollo/client';

import type * as T from '../../../../schema/__generated__/types';
export type GenericTemplate_ProPayloadTemplateAssessmentResult_Fragment = {
  __typename?: 'ProPayloadTemplateAssessmentResult';
} & BaseTemplateFields_ProPayloadTemplateAssessmentResult_Fragment;

export type GenericTemplate_ProPayloadTemplateBenchmarkResult_Fragment = {
  __typename?: 'ProPayloadTemplateBenchmarkResult';
} & BaseTemplateFields_ProPayloadTemplateBenchmarkResult_Fragment;

export type GenericTemplate_ProPayloadTemplateClinicalSupplyResult_Fragment = {
  __typename?: 'ProPayloadTemplateClinicalSupplyResult';
} & BaseTemplateFields_ProPayloadTemplateClinicalSupplyResult_Fragment &
  ClinicalSupplyTemplateFragment;

export type GenericTemplate_ProPayloadTemplateDecisionResult_Fragment = {
  __typename?: 'ProPayloadTemplateDecisionResult';
} & BaseTemplateFields_ProPayloadTemplateDecisionResult_Fragment &
  DecisionTemplateFragment;

export type GenericTemplate_ProPayloadTemplateErrorResult_Fragment = {
  __typename?: 'ProPayloadTemplateErrorResult';
} & BaseTemplateFields_ProPayloadTemplateErrorResult_Fragment &
  ErrorTemplateFragment;

export type GenericTemplate_ProPayloadTemplateGenericScenarioResult_Fragment = {
  __typename?: 'ProPayloadTemplateGenericScenarioResult';
} & BaseTemplateFields_ProPayloadTemplateGenericScenarioResult_Fragment &
  GenericScenarioTemplateFragment;

export type GenericTemplate_ProPayloadTemplateGetMoneyResult_Fragment = {
  __typename?: 'ProPayloadTemplateGetMoneyResult';
} & BaseTemplateFields_ProPayloadTemplateGetMoneyResult_Fragment &
  GetMoneyTemplateFragment;

export type GenericTemplate_ProPayloadTemplateImpRoadResult_Fragment = {
  __typename?: 'ProPayloadTemplateImpRoadResult';
} & BaseTemplateFields_ProPayloadTemplateImpRoadResult_Fragment &
  ImpRoadTemplateFragment;

export type GenericTemplate_ProPayloadTemplateInsightsResult_Fragment = {
  __typename?: 'ProPayloadTemplateInsightsResult';
} & BaseTemplateFields_ProPayloadTemplateInsightsResult_Fragment &
  InsightsTemplateFragment;

export type GenericTemplate_ProPayloadTemplateInsightsSentimentResult_Fragment = {
  __typename?: 'ProPayloadTemplateInsightsSentimentResult';
} & BaseTemplateFields_ProPayloadTemplateInsightsSentimentResult_Fragment &
  InsightsSentimentTemplateFragment;

export type GenericTemplate_ProPayloadTemplateIntroResult_Fragment = {
  __typename?: 'ProPayloadTemplateIntroResult';
} & BaseTemplateFields_ProPayloadTemplateIntroResult_Fragment &
  IntroTemplateFragment;

export type GenericTemplate_ProPayloadTemplateIrDecisionsResult_Fragment = {
  __typename?: 'ProPayloadTemplateIrDecisionsResult';
} & BaseTemplateFields_ProPayloadTemplateIrDecisionsResult_Fragment &
  IrDecisionsTemplateFragment;

export type GenericTemplate_ProPayloadTemplateIrFocusResult_Fragment = {
  __typename?: 'ProPayloadTemplateIrFocusResult';
} & BaseTemplateFields_ProPayloadTemplateIrFocusResult_Fragment &
  IrFocusTemplateFragment;

export type GenericTemplate_ProPayloadTemplateLaunchesResult_Fragment = {
  __typename?: 'ProPayloadTemplateLaunchesResult';
} & BaseTemplateFields_ProPayloadTemplateLaunchesResult_Fragment &
  LaunchesTemplateFragment;

export type GenericTemplate_ProPayloadTemplateLeaderboardInsightsResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeaderboardInsightsResult';
} & BaseTemplateFields_ProPayloadTemplateLeaderboardInsightsResult_Fragment &
  LeaderboardInsightsTemplateFragment;

export type GenericTemplate_ProPayloadTemplateLeaderboardResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeaderboardResult';
} & BaseTemplateFields_ProPayloadTemplateLeaderboardResult_Fragment &
  LeaderboardTemplateFragment;

export type GenericTemplate_ProPayloadTemplateLeaderboardTopResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeaderboardTopResult';
} & BaseTemplateFields_ProPayloadTemplateLeaderboardTopResult_Fragment &
  LeaderboardTopTemplateFragment;

export type GenericTemplate_ProPayloadTemplateLeversResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeversResult';
} & BaseTemplateFields_ProPayloadTemplateLeversResult_Fragment &
  LeversTemplateFragment;

export type GenericTemplate_ProPayloadTemplateMessageInsightResult_Fragment = {
  __typename?: 'ProPayloadTemplateMessageInsightResult';
} & BaseTemplateFields_ProPayloadTemplateMessageInsightResult_Fragment &
  MessageInsightTemplateFragment;

export type GenericTemplate_ProPayloadTemplateOutroResult_Fragment = {
  __typename?: 'ProPayloadTemplateOutroResult';
} & BaseTemplateFields_ProPayloadTemplateOutroResult_Fragment &
  OutroTemplateFragment;

export type GenericTemplate_ProPayloadTemplateOverviewResult_Fragment = {
  __typename?: 'ProPayloadTemplateOverviewResult';
} & BaseTemplateFields_ProPayloadTemplateOverviewResult_Fragment;

export type GenericTemplate_ProPayloadTemplatePharmaProductResult_Fragment = {
  __typename?: 'ProPayloadTemplatePharmaProductResult';
} & BaseTemplateFields_ProPayloadTemplatePharmaProductResult_Fragment &
  PharmaProductTemplateFragment;

export type GenericTemplate_ProPayloadTemplatePortfolioResult_Fragment = {
  __typename?: 'ProPayloadTemplatePortfolioResult';
} & BaseTemplateFields_ProPayloadTemplatePortfolioResult_Fragment &
  PortfolioTemplateFragment;

export type GenericTemplate_ProPayloadTemplateProgressBarsColumnResult_Fragment = {
  __typename?: 'ProPayloadTemplateProgressBarsColumnResult';
} & BaseTemplateFields_ProPayloadTemplateProgressBarsColumnResult_Fragment &
  ProgressBarsColumnTemplateFragment;

export type GenericTemplate_ProPayloadTemplateProgressBarsResult_Fragment = {
  __typename?: 'ProPayloadTemplateProgressBarsResult';
} & BaseTemplateFields_ProPayloadTemplateProgressBarsResult_Fragment &
  ProgressBarsTemplateFragment;

export type GenericTemplate_ProPayloadTemplateRecommendationsBaseResult_Fragment = {
  __typename?: 'ProPayloadTemplateRecommendationsBaseResult';
} & BaseTemplateFields_ProPayloadTemplateRecommendationsBaseResult_Fragment &
  RecommendationsBaseTemplateFragment;

export type GenericTemplate_ProPayloadTemplateRecommendationsInsightsResult_Fragment = {
  __typename?: 'ProPayloadTemplateRecommendationsInsightsResult';
} & BaseTemplateFields_ProPayloadTemplateRecommendationsInsightsResult_Fragment &
  RecommendationsInsightsTemplateFragment;

export type GenericTemplate_ProPayloadTemplateRelatedDecisionsResult_Fragment = {
  __typename?: 'ProPayloadTemplateRelatedDecisionsResult';
} & BaseTemplateFields_ProPayloadTemplateRelatedDecisionsResult_Fragment &
  RelatedDecisionsTemplateFragment;

export type GenericTemplate_ProPayloadTemplateSummaryResult_Fragment = {
  __typename?: 'ProPayloadTemplateSummaryResult';
} & BaseTemplateFields_ProPayloadTemplateSummaryResult_Fragment &
  SummaryTemplateFragment;

export type GenericTemplate_ProPayloadTemplateTrialsResult_Fragment = {
  __typename?: 'ProPayloadTemplateTrialsResult';
} & BaseTemplateFields_ProPayloadTemplateTrialsResult_Fragment &
  TrialsTemplateFragment;

export type GenericTemplateFragment =
  | GenericTemplate_ProPayloadTemplateAssessmentResult_Fragment
  | GenericTemplate_ProPayloadTemplateBenchmarkResult_Fragment
  | GenericTemplate_ProPayloadTemplateClinicalSupplyResult_Fragment
  | GenericTemplate_ProPayloadTemplateDecisionResult_Fragment
  | GenericTemplate_ProPayloadTemplateErrorResult_Fragment
  | GenericTemplate_ProPayloadTemplateGenericScenarioResult_Fragment
  | GenericTemplate_ProPayloadTemplateGetMoneyResult_Fragment
  | GenericTemplate_ProPayloadTemplateImpRoadResult_Fragment
  | GenericTemplate_ProPayloadTemplateInsightsResult_Fragment
  | GenericTemplate_ProPayloadTemplateInsightsSentimentResult_Fragment
  | GenericTemplate_ProPayloadTemplateIntroResult_Fragment
  | GenericTemplate_ProPayloadTemplateIrDecisionsResult_Fragment
  | GenericTemplate_ProPayloadTemplateIrFocusResult_Fragment
  | GenericTemplate_ProPayloadTemplateLaunchesResult_Fragment
  | GenericTemplate_ProPayloadTemplateLeaderboardInsightsResult_Fragment
  | GenericTemplate_ProPayloadTemplateLeaderboardResult_Fragment
  | GenericTemplate_ProPayloadTemplateLeaderboardTopResult_Fragment
  | GenericTemplate_ProPayloadTemplateLeversResult_Fragment
  | GenericTemplate_ProPayloadTemplateMessageInsightResult_Fragment
  | GenericTemplate_ProPayloadTemplateOutroResult_Fragment
  | GenericTemplate_ProPayloadTemplateOverviewResult_Fragment
  | GenericTemplate_ProPayloadTemplatePharmaProductResult_Fragment
  | GenericTemplate_ProPayloadTemplatePortfolioResult_Fragment
  | GenericTemplate_ProPayloadTemplateProgressBarsColumnResult_Fragment
  | GenericTemplate_ProPayloadTemplateProgressBarsResult_Fragment
  | GenericTemplate_ProPayloadTemplateRecommendationsBaseResult_Fragment
  | GenericTemplate_ProPayloadTemplateRecommendationsInsightsResult_Fragment
  | GenericTemplate_ProPayloadTemplateRelatedDecisionsResult_Fragment
  | GenericTemplate_ProPayloadTemplateSummaryResult_Fragment
  | GenericTemplate_ProPayloadTemplateTrialsResult_Fragment;

export type BaseTemplateFields_ProPayloadTemplateAssessmentResult_Fragment = {
  __typename?: 'ProPayloadTemplateAssessmentResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateBenchmarkResult_Fragment = {
  __typename?: 'ProPayloadTemplateBenchmarkResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateClinicalSupplyResult_Fragment = {
  __typename?: 'ProPayloadTemplateClinicalSupplyResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateDecisionResult_Fragment = {
  __typename?: 'ProPayloadTemplateDecisionResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateErrorResult_Fragment = {
  __typename?: 'ProPayloadTemplateErrorResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateGenericScenarioResult_Fragment = {
  __typename?: 'ProPayloadTemplateGenericScenarioResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateGetMoneyResult_Fragment = {
  __typename?: 'ProPayloadTemplateGetMoneyResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateImpRoadResult_Fragment = {
  __typename?: 'ProPayloadTemplateImpRoadResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateInsightsResult_Fragment = {
  __typename?: 'ProPayloadTemplateInsightsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateInsightsSentimentResult_Fragment = {
  __typename?: 'ProPayloadTemplateInsightsSentimentResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateIntroResult_Fragment = {
  __typename?: 'ProPayloadTemplateIntroResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateIrDecisionsResult_Fragment = {
  __typename?: 'ProPayloadTemplateIrDecisionsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateIrFocusResult_Fragment = {
  __typename?: 'ProPayloadTemplateIrFocusResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateLaunchesResult_Fragment = {
  __typename?: 'ProPayloadTemplateLaunchesResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateLeaderboardInsightsResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeaderboardInsightsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateLeaderboardResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeaderboardResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateLeaderboardTopResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeaderboardTopResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateLeversResult_Fragment = {
  __typename?: 'ProPayloadTemplateLeversResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateMessageInsightResult_Fragment = {
  __typename?: 'ProPayloadTemplateMessageInsightResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateOutroResult_Fragment = {
  __typename?: 'ProPayloadTemplateOutroResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateOverviewResult_Fragment = {
  __typename?: 'ProPayloadTemplateOverviewResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplatePharmaProductResult_Fragment = {
  __typename?: 'ProPayloadTemplatePharmaProductResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplatePortfolioResult_Fragment = {
  __typename?: 'ProPayloadTemplatePortfolioResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateProgressBarsColumnResult_Fragment = {
  __typename?: 'ProPayloadTemplateProgressBarsColumnResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateProgressBarsResult_Fragment = {
  __typename?: 'ProPayloadTemplateProgressBarsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateRecommendationsBaseResult_Fragment = {
  __typename?: 'ProPayloadTemplateRecommendationsBaseResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateRecommendationsInsightsResult_Fragment = {
  __typename?: 'ProPayloadTemplateRecommendationsInsightsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateRelatedDecisionsResult_Fragment = {
  __typename?: 'ProPayloadTemplateRelatedDecisionsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateSummaryResult_Fragment = {
  __typename?: 'ProPayloadTemplateSummaryResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFields_ProPayloadTemplateTrialsResult_Fragment = {
  __typename?: 'ProPayloadTemplateTrialsResult';
  id: string;
  templateName: string;
  templateType: T.TemplateType;
  title: string;
  subtitle?: string | null;
  moduleIds?: Array<string> | null;
  glassbox?: ({ __typename?: 'ProPayloadTemplateGlassBoxResult' } & GlassboxFieldsFragment) | null;
  audio?: ({ __typename?: 'ProPayloadTemplateAudioResult' } & AudioFieldsFragment) | null;
};

export type BaseTemplateFieldsFragment =
  | BaseTemplateFields_ProPayloadTemplateAssessmentResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateBenchmarkResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateClinicalSupplyResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateDecisionResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateErrorResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateGenericScenarioResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateGetMoneyResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateImpRoadResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateInsightsResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateInsightsSentimentResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateIntroResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateIrDecisionsResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateIrFocusResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateLaunchesResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateLeaderboardInsightsResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateLeaderboardResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateLeaderboardTopResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateLeversResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateMessageInsightResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateOutroResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateOverviewResult_Fragment
  | BaseTemplateFields_ProPayloadTemplatePharmaProductResult_Fragment
  | BaseTemplateFields_ProPayloadTemplatePortfolioResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateProgressBarsColumnResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateProgressBarsResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateRecommendationsBaseResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateRecommendationsInsightsResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateRelatedDecisionsResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateSummaryResult_Fragment
  | BaseTemplateFields_ProPayloadTemplateTrialsResult_Fragment;

export type ErrorTemplateFragment = {
  __typename?: 'ProPayloadTemplateErrorResult';
  requestedType: T.TemplateType;
};

export type ScenarioFieldsFragment = {
  __typename?: 'ProPayloadScenarioResult';
  scope: string;
  templates: Array<
    | ({
        __typename?: 'ProPayloadTemplateAssessmentResult';
      } & GenericTemplate_ProPayloadTemplateAssessmentResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateBenchmarkResult';
      } & GenericTemplate_ProPayloadTemplateBenchmarkResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateClinicalSupplyResult';
      } & GenericTemplate_ProPayloadTemplateClinicalSupplyResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateDecisionResult';
      } & GenericTemplate_ProPayloadTemplateDecisionResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateErrorResult';
      } & GenericTemplate_ProPayloadTemplateErrorResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateGenericScenarioResult';
      } & GenericTemplate_ProPayloadTemplateGenericScenarioResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateGetMoneyResult';
      } & GenericTemplate_ProPayloadTemplateGetMoneyResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateImpRoadResult';
      } & GenericTemplate_ProPayloadTemplateImpRoadResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateInsightsResult';
      } & GenericTemplate_ProPayloadTemplateInsightsResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateInsightsSentimentResult';
      } & GenericTemplate_ProPayloadTemplateInsightsSentimentResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateIntroResult';
      } & GenericTemplate_ProPayloadTemplateIntroResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateIrDecisionsResult';
      } & GenericTemplate_ProPayloadTemplateIrDecisionsResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateIrFocusResult';
      } & GenericTemplate_ProPayloadTemplateIrFocusResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateLaunchesResult';
      } & GenericTemplate_ProPayloadTemplateLaunchesResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateLeaderboardInsightsResult';
      } & GenericTemplate_ProPayloadTemplateLeaderboardInsightsResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateLeaderboardResult';
      } & GenericTemplate_ProPayloadTemplateLeaderboardResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateLeaderboardTopResult';
      } & GenericTemplate_ProPayloadTemplateLeaderboardTopResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateLeversResult';
      } & GenericTemplate_ProPayloadTemplateLeversResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateMessageInsightResult';
      } & GenericTemplate_ProPayloadTemplateMessageInsightResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateOutroResult';
      } & GenericTemplate_ProPayloadTemplateOutroResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateOverviewResult';
      } & GenericTemplate_ProPayloadTemplateOverviewResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplatePharmaProductResult';
      } & GenericTemplate_ProPayloadTemplatePharmaProductResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplatePortfolioResult';
      } & GenericTemplate_ProPayloadTemplatePortfolioResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateProgressBarsColumnResult';
      } & GenericTemplate_ProPayloadTemplateProgressBarsColumnResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateProgressBarsResult';
      } & GenericTemplate_ProPayloadTemplateProgressBarsResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateRecommendationsBaseResult';
      } & GenericTemplate_ProPayloadTemplateRecommendationsBaseResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateRecommendationsInsightsResult';
      } & GenericTemplate_ProPayloadTemplateRecommendationsInsightsResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateRelatedDecisionsResult';
      } & GenericTemplate_ProPayloadTemplateRelatedDecisionsResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateSummaryResult';
      } & GenericTemplate_ProPayloadTemplateSummaryResult_Fragment)
    | ({
        __typename?: 'ProPayloadTemplateTrialsResult';
      } & GenericTemplate_ProPayloadTemplateTrialsResult_Fragment)
    | null
  >;
  combinations: Array<{
    __typename?: 'ProPayloadTemplateCombinationResult';
    key: string;
    value: number;
  } | null>;
};

export type LeversTemplateFragment = {
  __typename?: 'ProPayloadTemplateLeversResult';
  leversName: string;
  optimisationLevers: Array<{
    __typename?: 'ProPayloadTemplateOptimisationLeverResult';
    name: string;
    numFreeLevers?: number | null;
    defaultScenario?: number | null;
    sliders?: Array<{
      __typename?: 'ProPayloadTemplateSliderResult';
      name: string;
      variables: Array<string>;
      defaultValue?: number | null;
    } | null> | null;
    scenarioDict: Array<{
      __typename?: 'ProPayloadTemplateScenarioResult';
      scenarioName: string;
      isRecommended?: boolean | null;
      isBase?: boolean | null;
      combinationArray: Array<number>;
      infoValue: Array<
        { __typename?: 'ProPayloadTemplateInfoValueResult' } & InfoValueFieldsFragment
      >;
      combinations: Array<{
        __typename?: 'ProPayloadTemplateCombinationResult';
        key: string;
        value: number;
      }>;
    }>;
  }>;
};

export type IntroTemplateFragment = {
  __typename?: 'ProPayloadTemplateIntroResult';
  introText: string;
};

export type OutroTemplateFragment = {
  __typename?: 'ProPayloadTemplateOutroResult';
  outroText: string;
  undoText?: string | null;
  doText?: string | null;
};

export type DecisionTemplateFragment = {
  __typename?: 'ProPayloadTemplateDecisionResult';
  noHighlightText1: string;
  noHighlightText2: string;
  completeText: string;
  highlightedText: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  highlightedTextLast?:
    | ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment)
    | null;
};

export type SummaryTemplateFragment = {
  __typename?: 'ProPayloadTemplateSummaryResult';
  mainMessage: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  bulletPoints: Array<
    { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment
  >;
};

export type RelatedDecisionsTemplateFragment = {
  __typename?: 'ProPayloadTemplateRelatedDecisionsResult';
  relatedDecisions: Array<{
    __typename?: 'ProPayloadTemplateRelatedDecisionResult';
    name: string;
    decisions: Array<{
      __typename?: 'ProPayloadTemplateDecisionsResult';
      title: string;
      values: Array<
        ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment) | null
      >;
    }>;
  }>;
};

export type GetMoneyTemplateFragment = {
  __typename?: 'ProPayloadTemplateGetMoneyResult';
  budgetSurplus: { __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment;
  generalPortfolio: {
    __typename?: 'ProPayloadTemplateDecisionsResult';
    title: string;
    values: Array<
      ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment) | null
    >;
  };
};

export type LaunchesTemplateFragment = {
  __typename?: 'ProPayloadTemplateLaunchesResult';
  valueType?: T.AgentValueType | null;
  lineChart: { __typename?: 'ProPayloadTemplateLineChartResult' } & LineChartFieldsFragment;
  scenarioTimeline: {
    __typename?: 'ProPayloadTemplateSentimentResult';
    text: string;
    sentiment: T.AgentSentiment;
  };
  timelineDiff: {
    __typename?: 'ProPayloadTemplateSentimentResult';
    text: string;
    sentiment: T.AgentSentiment;
  };
  baseCosts?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  scenarioCosts?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  basePeakSales?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  scenarioPeakSales?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  alternativeAssetValue?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  scenarioAssetValue?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  baseAssetValue?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  demand?: ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment) | null;
  launchDate?: {
    __typename?: 'ProPayloadTemplateSentimentResult';
    text: string;
    sentiment: T.AgentSentiment;
  } | null;
  competitionStatus?: {
    __typename?: 'ProPayloadTemplateSentimentResult';
    text: string;
    sentiment: T.AgentSentiment;
  } | null;
};

export type GenericScenarioTemplateFragment = {
  __typename?: 'ProPayloadTemplateGenericScenarioResult';
  title00?: string | null;
  title01?: string | null;
  title10?: string | null;
  title11?: string | null;
  lineChart: { __typename?: 'ProPayloadTemplateLineChartResult' } & LineChartFieldsFragment;
  bigTitle: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  smallTitle: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  content00?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  smallText00?:
    | ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment)
    | null;
  content01?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  smallText01?:
    | ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment)
    | null;
  content10?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  smallText10?:
    | ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment)
    | null;
  content11?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  smallText11?:
    | ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment)
    | null;
};

export type PortfolioTemplateFragment = {
  __typename?: 'ProPayloadTemplatePortfolioResult';
  valueType?: T.AgentValueType | null;
  lineChart: { __typename?: 'ProPayloadTemplateLineChartResult' } & LineChartFieldsFragment;
  scenarioTimeline: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  timelineDiff: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  baseCosts?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  scenarioCosts?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  basePeakSales?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  scenarioPeakSales?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  baseAssetValue?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  scenarioAssetValue?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  alternativeAssetValue?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  competitionStatus?:
    | ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment)
    | null;
};

export type TrialsTemplateFragment = {
  __typename?: 'ProPayloadTemplateTrialsResult';
  aiEnrollmentPrediction: {
    __typename?: 'ProPayloadTemplateSentimentResult';
  } & SentimentFieldsFragment;
  timeAccelerated: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  accelerateLpi: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
  sitesInitiate: {
    __typename?: 'ProPayloadTemplateSentimentValueResult';
  } & SentimentValueFieldsFragment;
  lineChart: { __typename?: 'ProPayloadTemplateLineChartResult' } & LineChartFieldsFragment;
};

export type ClinicalSupplyTemplateFragment = {
  __typename?: 'ProPayloadTemplateClinicalSupplyResult';
  aiEnrollmentPrediction: {
    __typename?: 'ProPayloadTemplateSentimentResult';
  } & SentimentFieldsFragment;
  sitesAvoidableWaste: Array<
    ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment) | null
  >;
  sitesExpirationRate: Array<
    ({ __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment) | null
  >;
  numKitsReduced?:
    | ({ __typename?: 'ProPayloadTemplateSentimentValueResult' } & SentimentValueFieldsFragment)
    | null;
  lineChart: { __typename?: 'ProPayloadTemplateLineChartResult' } & LineChartFieldsFragment;
};

export type PharmaProductTemplateFragment = {
  __typename?: 'ProPayloadTemplatePharmaProductResult';
  lineChart: { __typename?: 'ProPayloadTemplateLineChartResult' } & LineChartFieldsFragment;
  deltaRevenue: { __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment;
  newPresShare?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  deltaNewPres?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  totalPres?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  deltaPres?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  investment?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
};

export type RecommendationsBaseTemplateFragment = {
  __typename?: 'ProPayloadTemplateRecommendationsBaseResult';
  recMainMessage: string;
  topic: string;
  category: string;
  recommendations: Array<
    { __typename?: 'ProPayloadTemplateInfoValueResult' } & InfoValueFieldsFragment
  >;
};

export type RecommendationsInsightsTemplateFragment = {
  __typename?: 'ProPayloadTemplateRecommendationsInsightsResult';
  description: string;
  examplesText: string;
  examples: Array<string>;
  examplesFormatted: Array<string>;
  mainTitle: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
};

export type LeaderboardTemplateFragment = {
  __typename?: 'ProPayloadTemplateLeaderboardResult';
  leaderboardValues: Array<
    { __typename?: 'ProPayloadTemplateLeaderboardValueResult' } & LeaderboardValueFieldsFragment
  >;
};

export type LeaderboardTopTemplateFragment = {
  __typename?: 'ProPayloadTemplateLeaderboardTopResult';
  leaderboardTopValues: Array<
    { __typename?: 'ProPayloadTemplateLeaderboardValueResult' } & LeaderboardValueFieldsFragment
  >;
};

export type LeaderboardInsightsTemplateFragment = {
  __typename?: 'ProPayloadTemplateLeaderboardInsightsResult';
  leaderboardInsightsValues: Array<
    { __typename?: 'ProPayloadTemplateLeaderboardValueResult' } & LeaderboardValueFieldsFragment
  >;
};

export type ProgressBarsTemplateFragment = {
  __typename?: 'ProPayloadTemplateProgressBarsResult';
  barsTitle?: string | null;
  barsSummary?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  barsValues: Array<{
    __typename?: 'ProPayloadTemplateBarsValuesResult';
    title: string;
    values: Array<{ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment>;
  }>;
};

export type ProgressBarsColumnTemplateFragment = {
  __typename?: 'ProPayloadTemplateProgressBarsColumnResult';
  barsColumnTitle?: string | null;
  barsColumnSummary?:
    | ({ __typename?: 'ProPayloadTemplateFinancialResult' } & FinancialFieldsFragment)
    | null;
  barsColumnValues: Array<{
    __typename?: 'ProPayloadTemplateBarsColumnsValuesResult';
    title: string;
    values: Array<
      {
        __typename?: 'ProPayloadTemplateFinancialAdditionalValueResult';
      } & FinancialAdditionalValueFieldsFragment
    >;
  }>;
};

export type ImpRoadTemplateFragment = {
  __typename?: 'ProPayloadTemplateImpRoadResult';
  impRoadGlobalTitle: string;
  impRoadSections: Array<{
    __typename?: 'ProPayloadTemplateImpRoadSectionResult';
    title?: string | null;
    items: Array<{ __typename?: 'ProPayloadTemplateInfoValueResult' } & InfoValueFieldsFragment>;
  }>;
};

export type IrDecisionsTemplateFragment = {
  __typename?: 'ProPayloadTemplateIrDecisionsResult';
  irDecisionsGlobalTitle: string;
  irDecisionsSections: Array<{
    __typename?: 'ProPayloadTemplateIrDecisionsSectionResult';
    module: string;
    items: Array<{ __typename?: 'ProPayloadTemplateInfoValueResult' } & InfoValueFieldsFragment>;
  }>;
};

export type IrFocusTemplateFragment = {
  __typename?: 'ProPayloadTemplateIrFocusResult';
  irFocusGlobalTitle: string;
  irFocusMainItem: { __typename?: 'ProPayloadTemplateInfoValueResult' } & InfoValueFieldsFragment;
  irFocusItems: Array<
    { __typename?: 'ProPayloadTemplateInfoValueResult' } & InfoValueFieldsFragment
  >;
};

export type InsightsTemplateFragment = {
  __typename?: 'ProPayloadTemplateInsightsResult';
  insights: Array<
    { __typename?: 'ProPayloadTemplateInfoValueInsightsResult' } & InfoValueInsightsFieldsFragment
  >;
};

export type InsightsSentimentTemplateFragment = {
  __typename?: 'ProPayloadTemplateInsightsSentimentResult';
  upperTitle: string;
  competitors: Array<
    { __typename?: 'ProPayloadTemplateInfoValueInsightsResult' } & InfoValueInsightsFieldsFragment
  >;
  insightsText: {
    __typename?: 'ProPayloadTemplateInfoValueInsightsResult';
  } & InfoValueInsightsFieldsFragment;
};

export type MessageInsightTemplateFragment = {
  __typename?: 'ProPayloadTemplateMessageInsightResult';
  insightTopic: string;
  insightSubtopic: string;
  insightText: { __typename?: 'ProPayloadTemplateSentimentResult' } & SentimentFieldsFragment;
};

export type AudioFieldsFragment = {
  __typename?: 'ProPayloadTemplateAudioResult';
  script?: string | null;
  s3BucketName?: string | null;
  s3Folder?: string | null;
  audioPath?: string | null;
  audioFilename?: string | null;
  audioPathEncoded?: string | null;
};

export type GlassboxFieldsFragment = {
  __typename?: 'ProPayloadTemplateGlassBoxResult';
  title: string;
  subtitle?: string | null;
  content: string;
  sentiment: T.AgentSentiment;
};

export type FinancialFieldsFragment = {
  __typename?: 'ProPayloadTemplateFinancialResult';
  value?: string | null;
  currency?: string | null;
  text?: string | null;
  subtext?: string | null;
  sentiment: T.AgentSentiment;
  highlight?: boolean | null;
  isDelta?: boolean | null;
  formattedValue?: {
    __typename?: 'ProPayloadTemplateFormatedValueResult';
    text: string;
    sentiment: T.AgentSentiment;
  } | null;
};

export type FinancialAdditionalValueFieldsFragment = {
  __typename?: 'ProPayloadTemplateFinancialAdditionalValueResult';
  value?: string | null;
  currency?: string | null;
  text?: string | null;
  subtext?: string | null;
  sentiment: T.AgentSentiment;
  highlight: boolean;
  isDelta: boolean;
  value2?: string | null;
  currency2?: string | null;
  formattedValue: {
    __typename?: 'ProPayloadTemplateFormatedValueResult';
    text: string;
    sentiment: T.AgentSentiment;
  };
};

export type LineChartFieldsFragment = {
  __typename?: 'ProPayloadTemplateLineChartResult';
  chartType: T.AgentChartType;
  title: string;
  url?: string | null;
  xLabel: string;
  yLabel: string;
  series: Array<{
    __typename?: 'ProPayloadTemplateChartSeriesResult';
    xData?: Array<string> | null;
    yData?: Array<string> | null;
    isHighlighted?: boolean | null;
    markerIndex?: number | null;
    label: string;
    sentiment: T.AgentSentiment;
    xDataType: string;
    yDataType: string;
  }>;
};

export type InfoValueFieldsFragment = {
  __typename?: 'ProPayloadTemplateInfoValueResult';
  title: string;
  subtitle?: string | null;
  content: string;
  sentiment: T.AgentSentiment;
};

export type InfoValueInsightsFieldsFragment = {
  __typename?: 'ProPayloadTemplateInfoValueInsightsResult';
  title: string;
  subtitle?: string | null;
  content: string;
  sentiment: T.AgentSentiment;
  link?: string | null;
};

export type SentimentFieldsFragment = {
  __typename?: 'ProPayloadTemplateSentimentResult';
  sentiment: T.AgentSentiment;
  text: string;
};

export type SentimentValueFieldsFragment = {
  __typename?: 'ProPayloadTemplateSentimentValueResult';
  value: number;
  sentiment: T.AgentSentiment;
};

export type LeaderboardValueFieldsFragment = {
  __typename?: 'ProPayloadTemplateLeaderboardValueResult';
  value: number;
  sentiment?: T.AgentSentiment | null;
  rank: number;
  previousRank: number;
  isHighlighted: boolean;
  trend?: T.AgentTrendType | null;
  currency?: string | null;
  title?: string | null;
  subtitle?: string | null;
  body?: string | null;
  formattedRank?: string | null;
  formattedPreviousRank?: string | null;
  formattedValue?: {
    __typename?: 'ProPayloadTemplateFormatedValueResult';
    text: string;
    sentiment: T.AgentSentiment;
  } | null;
};

export const GlassboxFieldsFragmentDoc = gql`
  fragment GlassboxFields on ProPayloadTemplateGlassBoxResult {
    title
    subtitle
    content
    sentiment
  }
`;
export const AudioFieldsFragmentDoc = gql`
  fragment AudioFields on ProPayloadTemplateAudioResult {
    script
    s3BucketName
    s3Folder
    audioPath
    audioFilename
    audioPathEncoded
  }
`;
export const BaseTemplateFieldsFragmentDoc = gql`
  fragment BaseTemplateFields on IProPayloadTemplateResult {
    id
    templateName
    templateType
    title
    subtitle
    moduleIds
    glassbox {
      ...GlassboxFields
    }
    audio {
      ...AudioFields
    }
  }
  ${GlassboxFieldsFragmentDoc}
  ${AudioFieldsFragmentDoc}
`;
export const ErrorTemplateFragmentDoc = gql`
  fragment ErrorTemplate on ProPayloadTemplateErrorResult {
    requestedType
  }
`;
export const InfoValueFieldsFragmentDoc = gql`
  fragment InfoValueFields on ProPayloadTemplateInfoValueResult {
    title
    subtitle
    content
    sentiment
  }
`;
export const LeversTemplateFragmentDoc = gql`
  fragment LeversTemplate on ProPayloadTemplateLeversResult {
    leversName
    optimisationLevers {
      name
      numFreeLevers
      defaultScenario
      sliders {
        name
        variables
        defaultValue
      }
      scenarioDict {
        scenarioName
        infoValue {
          ...InfoValueFields
        }
        isRecommended
        isBase
        combinationArray
        combinations {
          key
          value
        }
      }
    }
  }
  ${InfoValueFieldsFragmentDoc}
`;
export const IntroTemplateFragmentDoc = gql`
  fragment IntroTemplate on ProPayloadTemplateIntroResult {
    introText
  }
`;
export const OutroTemplateFragmentDoc = gql`
  fragment OutroTemplate on ProPayloadTemplateOutroResult {
    outroText
    undoText
    doText
  }
`;
export const SentimentFieldsFragmentDoc = gql`
  fragment SentimentFields on ProPayloadTemplateSentimentResult {
    sentiment
    text
  }
`;
export const DecisionTemplateFragmentDoc = gql`
  fragment DecisionTemplate on ProPayloadTemplateDecisionResult {
    noHighlightText1
    highlightedText {
      ...SentimentFields
    }
    noHighlightText2
    highlightedTextLast {
      ...SentimentFields
    }
    completeText
  }
  ${SentimentFieldsFragmentDoc}
`;
export const SummaryTemplateFragmentDoc = gql`
  fragment SummaryTemplate on ProPayloadTemplateSummaryResult {
    mainMessage {
      ...SentimentFields
    }
    bulletPoints {
      ...SentimentFields
    }
  }
  ${SentimentFieldsFragmentDoc}
`;
export const FinancialFieldsFragmentDoc = gql`
  fragment FinancialFields on ProPayloadTemplateFinancialResult {
    value
    currency
    text
    subtext
    sentiment
    highlight
    isDelta
    formattedValue {
      text
      sentiment
    }
  }
`;
export const RelatedDecisionsTemplateFragmentDoc = gql`
  fragment RelatedDecisionsTemplate on ProPayloadTemplateRelatedDecisionsResult {
    relatedDecisions {
      name
      decisions {
        title
        values {
          ...FinancialFields
        }
      }
    }
  }
  ${FinancialFieldsFragmentDoc}
`;
export const GetMoneyTemplateFragmentDoc = gql`
  fragment GetMoneyTemplate on ProPayloadTemplateGetMoneyResult {
    budgetSurplus {
      ...FinancialFields
    }
    generalPortfolio {
      title
      values {
        ...FinancialFields
      }
    }
  }
  ${FinancialFieldsFragmentDoc}
`;
export const LineChartFieldsFragmentDoc = gql`
  fragment LineChartFields on ProPayloadTemplateLineChartResult {
    chartType
    title
    url
    xLabel
    yLabel
    series {
      xData
      yData
      isHighlighted
      markerIndex
      label
      sentiment
      xDataType
      yDataType
    }
  }
`;
export const LaunchesTemplateFragmentDoc = gql`
  fragment LaunchesTemplate on ProPayloadTemplateLaunchesResult {
    valueType
    lineChart {
      ...LineChartFields
    }
    scenarioTimeline {
      text
      sentiment
    }
    timelineDiff {
      text
      sentiment
    }
    baseCosts {
      ...FinancialFields
    }
    scenarioCosts {
      ...FinancialFields
    }
    basePeakSales {
      ...FinancialFields
    }
    scenarioPeakSales {
      ...FinancialFields
    }
    alternativeAssetValue {
      ...FinancialFields
    }
    scenarioAssetValue {
      ...FinancialFields
    }
    baseAssetValue {
      ...FinancialFields
    }
    demand {
      ...FinancialFields
    }
    launchDate {
      text
      sentiment
    }
    competitionStatus {
      text
      sentiment
    }
  }
  ${LineChartFieldsFragmentDoc}
  ${FinancialFieldsFragmentDoc}
`;
export const GenericScenarioTemplateFragmentDoc = gql`
  fragment GenericScenarioTemplate on ProPayloadTemplateGenericScenarioResult {
    lineChart {
      ...LineChartFields
    }
    bigTitle {
      ...SentimentFields
    }
    smallTitle {
      ...SentimentFields
    }
    title00
    content00 {
      ...FinancialFields
    }
    smallText00 {
      ...SentimentFields
    }
    title01
    content01 {
      ...FinancialFields
    }
    smallText01 {
      ...SentimentFields
    }
    title10
    content10 {
      ...FinancialFields
    }
    smallText10 {
      ...SentimentFields
    }
    title11
    content11 {
      ...FinancialFields
    }
    smallText11 {
      ...SentimentFields
    }
  }
  ${LineChartFieldsFragmentDoc}
  ${SentimentFieldsFragmentDoc}
  ${FinancialFieldsFragmentDoc}
`;
export const PortfolioTemplateFragmentDoc = gql`
  fragment PortfolioTemplate on ProPayloadTemplatePortfolioResult {
    lineChart {
      ...LineChartFields
    }
    scenarioTimeline {
      ...SentimentFields
    }
    timelineDiff {
      ...SentimentFields
    }
    baseCosts {
      ...FinancialFields
    }
    scenarioCosts {
      ...FinancialFields
    }
    basePeakSales {
      ...FinancialFields
    }
    scenarioPeakSales {
      ...FinancialFields
    }
    baseAssetValue {
      ...FinancialFields
    }
    scenarioAssetValue {
      ...FinancialFields
    }
    valueType
    alternativeAssetValue {
      ...FinancialFields
    }
    competitionStatus {
      ...SentimentFields
    }
  }
  ${LineChartFieldsFragmentDoc}
  ${SentimentFieldsFragmentDoc}
  ${FinancialFieldsFragmentDoc}
`;
export const SentimentValueFieldsFragmentDoc = gql`
  fragment SentimentValueFields on ProPayloadTemplateSentimentValueResult {
    value
    sentiment
  }
`;
export const TrialsTemplateFragmentDoc = gql`
  fragment TrialsTemplate on ProPayloadTemplateTrialsResult {
    aiEnrollmentPrediction {
      ...SentimentFields
    }
    timeAccelerated {
      ...SentimentFields
    }
    accelerateLpi {
      ...SentimentFields
    }
    sitesInitiate {
      ...SentimentValueFields
    }
    lineChart {
      ...LineChartFields
    }
  }
  ${SentimentFieldsFragmentDoc}
  ${SentimentValueFieldsFragmentDoc}
  ${LineChartFieldsFragmentDoc}
`;
export const ClinicalSupplyTemplateFragmentDoc = gql`
  fragment ClinicalSupplyTemplate on ProPayloadTemplateClinicalSupplyResult {
    aiEnrollmentPrediction {
      ...SentimentFields
    }
    sitesAvoidableWaste {
      ...SentimentFields
    }
    sitesExpirationRate {
      ...SentimentFields
    }
    numKitsReduced {
      ...SentimentValueFields
    }
    lineChart {
      ...LineChartFields
    }
  }
  ${SentimentFieldsFragmentDoc}
  ${SentimentValueFieldsFragmentDoc}
  ${LineChartFieldsFragmentDoc}
`;
export const PharmaProductTemplateFragmentDoc = gql`
  fragment PharmaProductTemplate on ProPayloadTemplatePharmaProductResult {
    lineChart {
      ...LineChartFields
    }
    deltaRevenue {
      ...FinancialFields
    }
    newPresShare {
      ...FinancialFields
    }
    deltaNewPres {
      ...FinancialFields
    }
    totalPres {
      ...FinancialFields
    }
    deltaPres {
      ...FinancialFields
    }
    investment {
      ...FinancialFields
    }
  }
  ${LineChartFieldsFragmentDoc}
  ${FinancialFieldsFragmentDoc}
`;
export const RecommendationsBaseTemplateFragmentDoc = gql`
  fragment RecommendationsBaseTemplate on ProPayloadTemplateRecommendationsBaseResult {
    recMainMessage
    topic
    category
    recommendations {
      ...InfoValueFields
    }
  }
  ${InfoValueFieldsFragmentDoc}
`;
export const RecommendationsInsightsTemplateFragmentDoc = gql`
  fragment RecommendationsInsightsTemplate on ProPayloadTemplateRecommendationsInsightsResult {
    mainTitle {
      ...SentimentFields
    }
    description
    examplesText
    examples
    examplesFormatted
  }
  ${SentimentFieldsFragmentDoc}
`;
export const LeaderboardValueFieldsFragmentDoc = gql`
  fragment LeaderboardValueFields on ProPayloadTemplateLeaderboardValueResult {
    value
    sentiment
    rank
    previousRank
    isHighlighted
    trend
    currency
    title
    subtitle
    body
    formattedValue {
      text
      sentiment
    }
    formattedRank
    formattedPreviousRank
  }
`;
export const LeaderboardTemplateFragmentDoc = gql`
  fragment LeaderboardTemplate on ProPayloadTemplateLeaderboardResult {
    leaderboardValues {
      ...LeaderboardValueFields
    }
  }
  ${LeaderboardValueFieldsFragmentDoc}
`;
export const LeaderboardTopTemplateFragmentDoc = gql`
  fragment LeaderboardTopTemplate on ProPayloadTemplateLeaderboardTopResult {
    leaderboardTopValues {
      ...LeaderboardValueFields
    }
  }
  ${LeaderboardValueFieldsFragmentDoc}
`;
export const LeaderboardInsightsTemplateFragmentDoc = gql`
  fragment LeaderboardInsightsTemplate on ProPayloadTemplateLeaderboardInsightsResult {
    leaderboardInsightsValues {
      ...LeaderboardValueFields
    }
  }
  ${LeaderboardValueFieldsFragmentDoc}
`;
export const ProgressBarsTemplateFragmentDoc = gql`
  fragment ProgressBarsTemplate on ProPayloadTemplateProgressBarsResult {
    barsSummary {
      ...FinancialFields
    }
    barsTitle
    barsValues {
      title
      values {
        ...FinancialFields
      }
    }
  }
  ${FinancialFieldsFragmentDoc}
`;
export const FinancialAdditionalValueFieldsFragmentDoc = gql`
  fragment FinancialAdditionalValueFields on ProPayloadTemplateFinancialAdditionalValueResult {
    value
    currency
    text
    subtext
    sentiment
    highlight
    isDelta
    formattedValue {
      text
      sentiment
    }
    value2
    currency2
  }
`;
export const ProgressBarsColumnTemplateFragmentDoc = gql`
  fragment ProgressBarsColumnTemplate on ProPayloadTemplateProgressBarsColumnResult {
    barsColumnSummary {
      ...FinancialFields
    }
    barsColumnTitle
    barsColumnValues {
      title
      values {
        ...FinancialAdditionalValueFields
      }
    }
  }
  ${FinancialFieldsFragmentDoc}
  ${FinancialAdditionalValueFieldsFragmentDoc}
`;
export const ImpRoadTemplateFragmentDoc = gql`
  fragment ImpRoadTemplate on ProPayloadTemplateImpRoadResult {
    impRoadGlobalTitle
    impRoadSections {
      title
      items {
        ...InfoValueFields
      }
    }
  }
  ${InfoValueFieldsFragmentDoc}
`;
export const IrDecisionsTemplateFragmentDoc = gql`
  fragment IrDecisionsTemplate on ProPayloadTemplateIrDecisionsResult {
    irDecisionsGlobalTitle
    irDecisionsSections {
      module
      items {
        ...InfoValueFields
      }
    }
  }
  ${InfoValueFieldsFragmentDoc}
`;
export const IrFocusTemplateFragmentDoc = gql`
  fragment IrFocusTemplate on ProPayloadTemplateIrFocusResult {
    irFocusGlobalTitle
    irFocusMainItem {
      ...InfoValueFields
    }
    irFocusItems {
      ...InfoValueFields
    }
  }
  ${InfoValueFieldsFragmentDoc}
`;
export const InfoValueInsightsFieldsFragmentDoc = gql`
  fragment InfoValueInsightsFields on ProPayloadTemplateInfoValueInsightsResult {
    title
    subtitle
    content
    sentiment
    link
  }
`;
export const InsightsTemplateFragmentDoc = gql`
  fragment InsightsTemplate on ProPayloadTemplateInsightsResult {
    insights {
      ...InfoValueInsightsFields
    }
  }
  ${InfoValueInsightsFieldsFragmentDoc}
`;
export const InsightsSentimentTemplateFragmentDoc = gql`
  fragment InsightsSentimentTemplate on ProPayloadTemplateInsightsSentimentResult {
    upperTitle
    competitors {
      ...InfoValueInsightsFields
    }
    insightsText {
      ...InfoValueInsightsFields
    }
  }
  ${InfoValueInsightsFieldsFragmentDoc}
`;
export const MessageInsightTemplateFragmentDoc = gql`
  fragment MessageInsightTemplate on ProPayloadTemplateMessageInsightResult {
    insightTopic
    insightSubtopic
    insightText {
      ...SentimentFields
    }
  }
  ${SentimentFieldsFragmentDoc}
`;
export const GenericTemplateFragmentDoc = gql`
  fragment GenericTemplate on IProPayloadTemplateResult {
    ...BaseTemplateFields
    ...ErrorTemplate
    ...LeversTemplate
    ...IntroTemplate
    ...OutroTemplate
    ...DecisionTemplate
    ...SummaryTemplate
    ...RelatedDecisionsTemplate
    ...GetMoneyTemplate
    ...LaunchesTemplate
    ...GenericScenarioTemplate
    ...PortfolioTemplate
    ...TrialsTemplate
    ...ClinicalSupplyTemplate
    ...PharmaProductTemplate
    ...RecommendationsBaseTemplate
    ...RecommendationsInsightsTemplate
    ...LeaderboardTemplate
    ...LeaderboardTopTemplate
    ...LeaderboardInsightsTemplate
    ...ProgressBarsTemplate
    ...ProgressBarsColumnTemplate
    ...ImpRoadTemplate
    ...IrDecisionsTemplate
    ...IrFocusTemplate
    ...InsightsTemplate
    ...InsightsSentimentTemplate
    ...MessageInsightTemplate
  }
  ${BaseTemplateFieldsFragmentDoc}
  ${ErrorTemplateFragmentDoc}
  ${LeversTemplateFragmentDoc}
  ${IntroTemplateFragmentDoc}
  ${OutroTemplateFragmentDoc}
  ${DecisionTemplateFragmentDoc}
  ${SummaryTemplateFragmentDoc}
  ${RelatedDecisionsTemplateFragmentDoc}
  ${GetMoneyTemplateFragmentDoc}
  ${LaunchesTemplateFragmentDoc}
  ${GenericScenarioTemplateFragmentDoc}
  ${PortfolioTemplateFragmentDoc}
  ${TrialsTemplateFragmentDoc}
  ${ClinicalSupplyTemplateFragmentDoc}
  ${PharmaProductTemplateFragmentDoc}
  ${RecommendationsBaseTemplateFragmentDoc}
  ${RecommendationsInsightsTemplateFragmentDoc}
  ${LeaderboardTemplateFragmentDoc}
  ${LeaderboardTopTemplateFragmentDoc}
  ${LeaderboardInsightsTemplateFragmentDoc}
  ${ProgressBarsTemplateFragmentDoc}
  ${ProgressBarsColumnTemplateFragmentDoc}
  ${ImpRoadTemplateFragmentDoc}
  ${IrDecisionsTemplateFragmentDoc}
  ${IrFocusTemplateFragmentDoc}
  ${InsightsTemplateFragmentDoc}
  ${InsightsSentimentTemplateFragmentDoc}
  ${MessageInsightTemplateFragmentDoc}
`;
export const ScenarioFieldsFragmentDoc = gql`
  fragment ScenarioFields on ProPayloadScenarioResult {
    scope
    templates {
      ...GenericTemplate
    }
    combinations {
      key
      value
    }
  }
  ${GenericTemplateFragmentDoc}
`;
