import { map } from 'lodash-es';
import React from 'react';

import { Stack } from '../../../../../../Stack';
import ParagraphSentiment, {
  ParagraphSentimentProps,
} from './ParagraphSentiment/ParagraphSentiment';

export interface ParagraphSentimentListProps {
  rows: ParagraphSentimentProps[];
}

const ParagraphSentimentList: React.FC<ParagraphSentimentListProps> = ({ rows }) => {
  return (
    <Stack>
      {map(rows, (row, index) => (
        <ParagraphSentiment key={index} {...row} />
      ))}
    </Stack>
  );
};

export default ParagraphSentimentList;
