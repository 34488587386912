import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material';
import { FC } from 'react';

import { Typography } from '../../../../../Typography';

export type AgentGenericScenarioTemplateSentiment = 'positive' | 'negative';

export interface AgentGenericScenarioTemplateKpiValue {
  value: string;
  sentiment: AgentGenericScenarioTemplateSentiment;
}

export interface AgentGenericScenarioTemplateKpi {
  title: string;
  primaryValue: AgentGenericScenarioTemplateKpiValue;
  secondaryValue?: AgentGenericScenarioTemplateKpiValue;
}

export interface AgentGenericScenarioTemplateProps {
  chartComponent: React.ReactNode;
  kpis: AgentGenericScenarioTemplateKpi[];
}

const sentimentColorMap: Record<AgentGenericScenarioTemplateSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.medium,
}));

const Kpis = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.medium,
}));

const KpiRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.tokens.spacing.medium,
}));

const KpiItemRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 140,
}));

const KpiItem = ({ title, primaryValue, secondaryValue }: AgentGenericScenarioTemplateKpi) => (
  <KpiItemRoot>
    <Typography variant="body.small-bold-condensed" lineClamp={1}>
      {title}
    </Typography>
    <Typography
      variant="heading.H4"
      color={sentimentColorMap[primaryValue.sentiment]}
      lineClamp={1}
    >
      {primaryValue.value}
    </Typography>
    {secondaryValue && (
      <Typography
        variant="heading.H8"
        color={sentimentColorMap[secondaryValue.sentiment]}
        lineClamp={1}
      >
        {secondaryValue.value}
      </Typography>
    )}
  </KpiItemRoot>
);

export const AgentGenericScenarioTemplate: FC<AgentGenericScenarioTemplateProps> = ({
  chartComponent,
  kpis,
}) => (
  <Container>
    {chartComponent}
    <Kpis>
      <KpiRow>
        {kpis.slice(0, 2).map((kpi, index) => (
          <KpiItem key={index} {...kpi} />
        ))}
      </KpiRow>
      {kpis.length > 2 && (
        <KpiRow>
          {kpis.slice(2, 4).map((kpi, index) => (
            <KpiItem key={index} {...kpi} />
          ))}
        </KpiRow>
      )}
    </Kpis>
  </Container>
);
