const PauseIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      d="M7.06027 9.79922C6.68313 9.79922 6.36038 9.66505 6.09204 9.3967C5.82324 9.1279 5.68884 8.80493 5.68884 8.42779V1.57065C5.68884 1.1935 5.82324 0.870762 6.09204 0.602419C6.36038 0.333619 6.68313 0.199219 7.06027 0.199219H8.4317C8.80884 0.199219 9.13181 0.333619 9.40061 0.602419C9.66895 0.870762 9.80313 1.1935 9.80313 1.57065V8.42779C9.80313 8.80493 9.66895 9.1279 9.40061 9.3967C9.13181 9.66505 8.80884 9.79922 8.4317 9.79922H7.06027ZM1.57455 9.79922C1.19741 9.79922 0.874668 9.66505 0.606325 9.3967C0.337525 9.1279 0.203125 8.80493 0.203125 8.42779V1.57065C0.203125 1.1935 0.337525 0.870762 0.606325 0.602419C0.874668 0.333619 1.19741 0.199219 1.57455 0.199219H2.94598C3.32313 0.199219 3.6461 0.333619 3.9149 0.602419C4.18324 0.870762 4.31741 1.1935 4.31741 1.57065V8.42779C4.31741 8.80493 4.18324 9.1279 3.9149 9.3967C3.6461 9.66505 3.32313 9.79922 2.94598 9.79922H1.57455Z"
      fill="currentColor"
    />
  </svg>
);

export default PauseIcon;
