import { FC } from 'react';

export interface VerticalBarSymbolProps {
  size?: number;
  strokeWidth?: number;
}

const VerticalBarSymbol: FC<VerticalBarSymbolProps> = ({ size = 12, strokeWidth = 2 }) => (
  <svg width={size / 5} height={size} viewBox={`0 0 ${size / 5} ${size}`} fill="currentColor">
    <path
      d={`M${size / 5 / 2} ${strokeWidth / 2} V${size - strokeWidth / 2}`}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);

export default VerticalBarSymbol;
