import { useCallback, useEffect, useRef, useState } from 'react';

import type { AilyAgentEventData, AilyAgentOperation } from '../classes';
import { useAilyAgent } from '../providers';

/**
 * Hook for managing AilyAgent operations with ref collection and external metadata mapping.
 *
 * @param items - List of metadata items for each audio-enabled operation.
 * @param enabled - Whether the agent should be active (e.g. based on `audioEnabled`).
 * @param mapToOperation - Maps a metadata item and a ref to an AilyAgentOperation.
 * @param onStep - Optional callback fired when agent steps to a new operation.
 */
export function useAilyAgentOperations<T>(
  items: T[],
  enabled: boolean,
  mapToOperation: (item: T, ref: HTMLElement) => AilyAgentOperation,
  onStep?: (index: number, item: T) => void,
) {
  const { agent } = useAilyAgent();
  const refs = useRef<(HTMLElement | null)[]>(Array(items.length).fill(null));
  const [refsReady, setRefsReady] = useState(false);

  const setRef = useCallback(
    (index: number) => (node: HTMLElement | null) => {
      refs.current[index] = node;
      if (refs.current.every((ref) => ref !== null) && !refsReady) {
        setRefsReady(true);
      }
    },
    [refsReady],
  );

  useEffect(() => {
    if (!refsReady || !enabled) return;

    const operations = items.map((item, index) => mapToOperation(item, refs.current[index]!));

    if (operations.length) {
      agent.resetAndStart(operations);
    }

    return () => {
      agent.exit();
    };
  }, [refsReady, enabled, items, mapToOperation, agent]);

  useEffect(() => {
    if (!onStep) return;

    const handleStep = (data: AilyAgentEventData) => {
      if (data.index !== null && items[data.index]) {
        onStep(data.index, items[data.index]);
      }
    };

    agent.addEventListener('step', handleStep);

    return () => {
      agent.removeEventListener('step', handleStep);
    };
  }, [agent, items, onStep]);

  return { setRef };
}
