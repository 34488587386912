import {
  IProPayloadTemplateResult,
  isProPayloadTemplateDecisionResult,
  TemplateType,
} from '@aily/graphql-sdk/schema';
import { AgentFooter, AgentPopupBase } from '@aily/ui-components';
import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { Slideshow, SlideshowProps, SlideshowProvider } from '../../../components';
import AgentIntroSlideTemplate from '../components/AgentIntroSlideTemplate/AgentIntroSlideTemplate';
import { useAgentDataContext } from '../components/AgentModal/contexts/AgentDataContext';
import { AgentSlideshow } from '../components/AgentSlideshow/AgentSlideshow';
import { AgentSlideshowSlideContent } from '../components/AgentSlideshow/AgentSlideshowSlideContent';
import { SlideshowFooterControls } from '../components/SlideshowFooterControls';
import Template from '../components/Template/Template';
import { useAilyAgent } from '../providers';
import DecisionTemplate from '../templates/DecisionTemplate';

const SCREENS_PER_SLIDE = 3;

interface AgentSummarizerLayoutProps extends Omit<SlideshowProps, 'children'> {
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

export const AgentSummarizerLayout: React.FC<AgentSummarizerLayoutProps> = ({
  onAgentClose,
  onBackdropClose,
  ...rest
}) => {
  const { agentIntroData, agentTemplatesData } = useAgentDataContext();
  const { isPopupOpen, setIsPopupOpen, setPopupData, popupData } = useAilyAgent();

  const totalSlides = useMemo(() => {
    return Math.ceil(agentTemplatesData?.length / SCREENS_PER_SLIDE);
  }, [agentTemplatesData]);

  const handleAgentClose = useCallback(() => {
    onAgentClose?.();
    onBackdropClose?.(true);
  }, [onAgentClose, onBackdropClose]);

  const handlePopupClose = useCallback(() => {
    setIsPopupOpen(false);
    setPopupData([]);
  }, [setIsPopupOpen, setPopupData]);

  const renderHeader = useCallback(() => {
    const screen = agentTemplatesData.find(
      (template) => template.parent?.templateType === TemplateType.Decision,
    );

    if (!screen || !screen.parent || !isProPayloadTemplateDecisionResult(screen.parent)) {
      return null;
    }

    return (
      <DecisionTemplate
        data={screen.parent}
        popups={(screen.popups ?? []) as IProPayloadTemplateResult[]}
        onAgentClose={handleAgentClose}
        isTitleComponent
      />
    );
  }, [agentTemplatesData, handleAgentClose]);

  const introScreenGroup = useMemo(() => {
    if (!agentIntroData || !agentIntroData?.[0]?.parent) {
      return null;
    }

    return (
      <Slideshow.SlideGroup key={`slide-group-intro`} index={0}>
        <Slideshow.Slide key="slide-intro" index={0}>
          <AgentIntroSlideTemplate template={agentIntroData[0].parent} />
        </Slideshow.Slide>
      </Slideshow.SlideGroup>
    );
  }, [agentIntroData]);

  const slideScreensGroup = useMemo(() => {
    return (
      // starts on index 1, as we have an intro screen group that takes the whole display
      <Slideshow.SlideGroup index={1}>
        {Array.from({ length: totalSlides }, (_, slideIndex) => {
          const start = slideIndex * SCREENS_PER_SLIDE;
          const end = start + SCREENS_PER_SLIDE;
          const templatesData = agentTemplatesData?.slice(start, end) || [];

          return (
            <Slideshow.Slide key={`slide-${slideIndex}`} index={slideIndex}>
              <AgentSlideshowSlideContent
                templatesData={templatesData}
                onAgentClose={onAgentClose}
                onBackdropClose={onBackdropClose}
              />
            </Slideshow.Slide>
          );
        })}
      </Slideshow.SlideGroup>
    );
  }, [agentTemplatesData, totalSlides, onAgentClose, onBackdropClose]);

  return (
    <>
      {/* // re - when building the second layout, check how you can extract the slideshow wrapper */}
      <SlideshowProvider>
        <Box position="relative" data-testid="slideshow-builder-container">
          <AgentSlideshow data-testid="slideshow-container" {...rest}>
            <Slideshow.Screen index={0} header={renderHeader()}>
              {introScreenGroup}
              {slideScreensGroup}
            </Slideshow.Screen>
          </AgentSlideshow>
        </Box>

        <AgentFooter sx={{ position: 'absolute', bottom: 0 }}>
          <SlideshowFooterControls />
        </AgentFooter>
      </SlideshowProvider>

      {popupData.length > 0 && (
        <AgentPopupBase
          title={popupData[0]?.title ?? ''}
          onClose={handlePopupClose}
          isOpen={isPopupOpen}
        >
          <Template templateData={popupData[0]} />
        </AgentPopupBase>
      )}
    </>
  );
};
