import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type { DataViewFields_ProAgentDetailsDataView_Fragment } from '../../../../core/__generated__/fragments';
import { DataViewFieldsFragmentDoc } from '../../../../core/__generated__/fragments';
import type * as T from '../../../../schema/__generated__/types';
import type {
  GenericTemplate_ProPayloadTemplateAssessmentResult_Fragment,
  GenericTemplate_ProPayloadTemplateBenchmarkResult_Fragment,
  GenericTemplate_ProPayloadTemplateClinicalSupplyResult_Fragment,
  GenericTemplate_ProPayloadTemplateDecisionResult_Fragment,
  GenericTemplate_ProPayloadTemplateErrorResult_Fragment,
  GenericTemplate_ProPayloadTemplateGenericScenarioResult_Fragment,
  GenericTemplate_ProPayloadTemplateGetMoneyResult_Fragment,
  GenericTemplate_ProPayloadTemplateImpRoadResult_Fragment,
  GenericTemplate_ProPayloadTemplateInsightsResult_Fragment,
  GenericTemplate_ProPayloadTemplateInsightsSentimentResult_Fragment,
  GenericTemplate_ProPayloadTemplateIntroResult_Fragment,
  GenericTemplate_ProPayloadTemplateIrDecisionsResult_Fragment,
  GenericTemplate_ProPayloadTemplateIrFocusResult_Fragment,
  GenericTemplate_ProPayloadTemplateLaunchesResult_Fragment,
  GenericTemplate_ProPayloadTemplateLeaderboardInsightsResult_Fragment,
  GenericTemplate_ProPayloadTemplateLeaderboardResult_Fragment,
  GenericTemplate_ProPayloadTemplateLeaderboardTopResult_Fragment,
  GenericTemplate_ProPayloadTemplateLeversResult_Fragment,
  GenericTemplate_ProPayloadTemplateMessageInsightResult_Fragment,
  GenericTemplate_ProPayloadTemplateOutroResult_Fragment,
  GenericTemplate_ProPayloadTemplateOverviewResult_Fragment,
  GenericTemplate_ProPayloadTemplatePharmaProductResult_Fragment,
  GenericTemplate_ProPayloadTemplatePortfolioResult_Fragment,
  GenericTemplate_ProPayloadTemplateProgressBarsColumnResult_Fragment,
  GenericTemplate_ProPayloadTemplateProgressBarsResult_Fragment,
  GenericTemplate_ProPayloadTemplateRecommendationsBaseResult_Fragment,
  GenericTemplate_ProPayloadTemplateRecommendationsInsightsResult_Fragment,
  GenericTemplate_ProPayloadTemplateRelatedDecisionsResult_Fragment,
  GenericTemplate_ProPayloadTemplateSummaryResult_Fragment,
  GenericTemplate_ProPayloadTemplateTrialsResult_Fragment,
  ScenarioFieldsFragment,
} from './fragments';
import { GenericTemplateFragmentDoc, ScenarioFieldsFragmentDoc } from './fragments';
const defaultOptions = {} as const;
export type GetProAgentDetailsInputQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProAgentDetailsDataInput>;
}>;

export type GetProAgentDetailsInputQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    agent?: {
      __typename?: 'AgentResult';
      details?:
        | ({
            __typename?: 'ProAgentDetailsDataView';
            id: string;
            title: string;
            structure?: {
              __typename?: 'ProAgentStructureResult';
              id: string;
              agentName: string;
              agentType?: T.AgentType | null;
              impAgent: {
                __typename?: 'ProAgentStructureCardResult';
                name?: string | null;
                agentType?: string | null;
                modules?: Array<string> | null;
                impact?: {
                  __typename?: 'ProAgentStructureCardImpactResult';
                  text?: string | null;
                  sentiment?: T.AgentSentiment | null;
                } | null;
              };
              optAgent?: {
                __typename?: 'ProAgentStructureCardResult';
                name?: string | null;
                agentType?: string | null;
                modules?: Array<string> | null;
                impact?: {
                  __typename?: 'ProAgentStructureCardImpactResult';
                  text?: string | null;
                  sentiment?: T.AgentSentiment | null;
                } | null;
              } | null;
              templates: Array<
                | {
                    __typename?: 'ProAgentStructureTemplateMobileResult';
                    templateId: string;
                    templateName: string;
                    templateType: T.TemplateType;
                    popups?: Array<{
                      __typename?: 'ProAgentStructureTemplateDescriptionResult';
                      templateId: string;
                      templateType: T.TemplateType;
                      templateName: string;
                    }> | null;
                    scenarios?: Array<{
                      __typename?: 'ProAgentStructureScenarioTemplatesResult';
                      leversScope: string;
                      scenarioTemplates: Array<{
                        __typename?: 'ProAgentStructureTemplateDescriptionResult';
                        templateId: string;
                        templateType: T.TemplateType;
                        templateName: string;
                      } | null>;
                      combinations: Array<{
                        __typename?: 'ProAgentStructureCombinationResult';
                        key: string;
                        value: number;
                      } | null>;
                    }> | null;
                  }
                | {
                    __typename?: 'ProAgentStructureTemplateWebResult';
                    templateId: string;
                    templateName: string;
                    templateType: T.TemplateType;
                    popups?: Array<{
                      __typename?: 'ProAgentStructureTemplateDescriptionResult';
                      templateId: string;
                      templateType: T.TemplateType;
                      templateName: string;
                    }> | null;
                    scenarios?: Array<{
                      __typename?: 'ProAgentStructureScenarioTemplatesResult';
                      leversScope: string;
                      scenarioTemplates: Array<{
                        __typename?: 'ProAgentStructureTemplateDescriptionResult';
                        templateId: string;
                        templateType: T.TemplateType;
                        templateName: string;
                      } | null>;
                      combinations: Array<{
                        __typename?: 'ProAgentStructureCombinationResult';
                        key: string;
                        value: number;
                      } | null>;
                    }> | null;
                  }
              >;
            } | null;
            decision?: {
              __typename?: 'ProAgentDecisionResult';
              agentId: string;
              screenIds: Array<string>;
              title: string;
              subTitle?: string | null;
            } | null;
          } & DataViewFields_ProAgentDetailsDataView_Fragment)
        | null;
    } | null;
  };
};

export type GetPayloadTemplatesQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProPayloadInput>;
}>;

export type GetPayloadTemplatesQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    agent?: {
      __typename?: 'AgentResult';
      payload?: {
        __typename?: 'ProAgentPayloadResult';
        templates: Array<{
          __typename?: 'ProAgentPayloadTemplatesResult';
          parent?:
            | ({
                __typename?: 'ProPayloadTemplateAssessmentResult';
              } & GenericTemplate_ProPayloadTemplateAssessmentResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateBenchmarkResult';
              } & GenericTemplate_ProPayloadTemplateBenchmarkResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateClinicalSupplyResult';
              } & GenericTemplate_ProPayloadTemplateClinicalSupplyResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateDecisionResult';
              } & GenericTemplate_ProPayloadTemplateDecisionResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateErrorResult';
              } & GenericTemplate_ProPayloadTemplateErrorResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateGenericScenarioResult';
              } & GenericTemplate_ProPayloadTemplateGenericScenarioResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateGetMoneyResult';
              } & GenericTemplate_ProPayloadTemplateGetMoneyResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateImpRoadResult';
              } & GenericTemplate_ProPayloadTemplateImpRoadResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateInsightsResult';
              } & GenericTemplate_ProPayloadTemplateInsightsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateInsightsSentimentResult';
              } & GenericTemplate_ProPayloadTemplateInsightsSentimentResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateIntroResult';
              } & GenericTemplate_ProPayloadTemplateIntroResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateIrDecisionsResult';
              } & GenericTemplate_ProPayloadTemplateIrDecisionsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateIrFocusResult';
              } & GenericTemplate_ProPayloadTemplateIrFocusResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLaunchesResult';
              } & GenericTemplate_ProPayloadTemplateLaunchesResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeaderboardInsightsResult';
              } & GenericTemplate_ProPayloadTemplateLeaderboardInsightsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeaderboardResult';
              } & GenericTemplate_ProPayloadTemplateLeaderboardResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeaderboardTopResult';
              } & GenericTemplate_ProPayloadTemplateLeaderboardTopResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeversResult';
              } & GenericTemplate_ProPayloadTemplateLeversResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateMessageInsightResult';
              } & GenericTemplate_ProPayloadTemplateMessageInsightResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateOutroResult';
              } & GenericTemplate_ProPayloadTemplateOutroResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateOverviewResult';
              } & GenericTemplate_ProPayloadTemplateOverviewResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplatePharmaProductResult';
              } & GenericTemplate_ProPayloadTemplatePharmaProductResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplatePortfolioResult';
              } & GenericTemplate_ProPayloadTemplatePortfolioResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateProgressBarsColumnResult';
              } & GenericTemplate_ProPayloadTemplateProgressBarsColumnResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateProgressBarsResult';
              } & GenericTemplate_ProPayloadTemplateProgressBarsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateRecommendationsBaseResult';
              } & GenericTemplate_ProPayloadTemplateRecommendationsBaseResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateRecommendationsInsightsResult';
              } & GenericTemplate_ProPayloadTemplateRecommendationsInsightsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateRelatedDecisionsResult';
              } & GenericTemplate_ProPayloadTemplateRelatedDecisionsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateSummaryResult';
              } & GenericTemplate_ProPayloadTemplateSummaryResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateTrialsResult';
              } & GenericTemplate_ProPayloadTemplateTrialsResult_Fragment)
            | null;
          popups?: Array<
            | ({
                __typename?: 'ProPayloadTemplateAssessmentResult';
              } & GenericTemplate_ProPayloadTemplateAssessmentResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateBenchmarkResult';
              } & GenericTemplate_ProPayloadTemplateBenchmarkResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateClinicalSupplyResult';
              } & GenericTemplate_ProPayloadTemplateClinicalSupplyResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateDecisionResult';
              } & GenericTemplate_ProPayloadTemplateDecisionResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateErrorResult';
              } & GenericTemplate_ProPayloadTemplateErrorResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateGenericScenarioResult';
              } & GenericTemplate_ProPayloadTemplateGenericScenarioResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateGetMoneyResult';
              } & GenericTemplate_ProPayloadTemplateGetMoneyResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateImpRoadResult';
              } & GenericTemplate_ProPayloadTemplateImpRoadResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateInsightsResult';
              } & GenericTemplate_ProPayloadTemplateInsightsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateInsightsSentimentResult';
              } & GenericTemplate_ProPayloadTemplateInsightsSentimentResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateIntroResult';
              } & GenericTemplate_ProPayloadTemplateIntroResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateIrDecisionsResult';
              } & GenericTemplate_ProPayloadTemplateIrDecisionsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateIrFocusResult';
              } & GenericTemplate_ProPayloadTemplateIrFocusResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLaunchesResult';
              } & GenericTemplate_ProPayloadTemplateLaunchesResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeaderboardInsightsResult';
              } & GenericTemplate_ProPayloadTemplateLeaderboardInsightsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeaderboardResult';
              } & GenericTemplate_ProPayloadTemplateLeaderboardResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeaderboardTopResult';
              } & GenericTemplate_ProPayloadTemplateLeaderboardTopResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateLeversResult';
              } & GenericTemplate_ProPayloadTemplateLeversResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateMessageInsightResult';
              } & GenericTemplate_ProPayloadTemplateMessageInsightResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateOutroResult';
              } & GenericTemplate_ProPayloadTemplateOutroResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateOverviewResult';
              } & GenericTemplate_ProPayloadTemplateOverviewResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplatePharmaProductResult';
              } & GenericTemplate_ProPayloadTemplatePharmaProductResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplatePortfolioResult';
              } & GenericTemplate_ProPayloadTemplatePortfolioResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateProgressBarsColumnResult';
              } & GenericTemplate_ProPayloadTemplateProgressBarsColumnResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateProgressBarsResult';
              } & GenericTemplate_ProPayloadTemplateProgressBarsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateRecommendationsBaseResult';
              } & GenericTemplate_ProPayloadTemplateRecommendationsBaseResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateRecommendationsInsightsResult';
              } & GenericTemplate_ProPayloadTemplateRecommendationsInsightsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateRelatedDecisionsResult';
              } & GenericTemplate_ProPayloadTemplateRelatedDecisionsResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateSummaryResult';
              } & GenericTemplate_ProPayloadTemplateSummaryResult_Fragment)
            | ({
                __typename?: 'ProPayloadTemplateTrialsResult';
              } & GenericTemplate_ProPayloadTemplateTrialsResult_Fragment)
            | null
          > | null;
          scenarios?: Array<
            ({ __typename?: 'ProPayloadScenarioResult' } & ScenarioFieldsFragment) | null
          > | null;
        }>;
      } | null;
    } | null;
  };
};

export const GetProAgentDetailsInputDocument = gql`
  query GetProAgentDetailsInput($input: ProAgentDetailsDataInput) {
    pro {
      agent {
        details(input: $input) {
          ...DataViewFields
          ... on ProAgentDetailsDataView {
            id
            title
          }
          structure {
            id
            agentName
            agentType
            impAgent {
              name
              agentType
              impact {
                text
                sentiment
              }
              modules
            }
            optAgent {
              name
              agentType
              modules
              impact {
                text
                sentiment
              }
            }
            templates {
              templateId
              templateName
              templateType
              popups {
                templateId
                templateType
                templateName
              }
              scenarios {
                leversScope
                scenarioTemplates {
                  templateId
                  templateType
                  templateName
                }
                combinations {
                  key
                  value
                }
              }
              ... on ProAgentStructureTemplateWebResult {
                templateId
                templateName
                templateType
              }
            }
          }
          decision {
            agentId
            screenIds
            title
            subTitle
          }
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
`;

/**
 * __useGetProAgentDetailsInputQuery__
 *
 * To run a query within a React component, call `useGetProAgentDetailsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProAgentDetailsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProAgentDetailsInputQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProAgentDetailsInputQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProAgentDetailsInputQuery,
    GetProAgentDetailsInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetProAgentDetailsInputQuery,
    GetProAgentDetailsInputQueryVariables
  >(GetProAgentDetailsInputDocument, options);
}
export function useGetProAgentDetailsInputLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProAgentDetailsInputQuery,
    GetProAgentDetailsInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProAgentDetailsInputQuery,
    GetProAgentDetailsInputQueryVariables
  >(GetProAgentDetailsInputDocument, options);
}
export function useGetProAgentDetailsInputSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProAgentDetailsInputQuery,
    GetProAgentDetailsInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProAgentDetailsInputQuery,
    GetProAgentDetailsInputQueryVariables
  >(GetProAgentDetailsInputDocument, options);
}
export type GetProAgentDetailsInputQueryHookResult = ReturnType<
  typeof useGetProAgentDetailsInputQuery
>;
export type GetProAgentDetailsInputLazyQueryHookResult = ReturnType<
  typeof useGetProAgentDetailsInputLazyQuery
>;
export type GetProAgentDetailsInputSuspenseQueryHookResult = ReturnType<
  typeof useGetProAgentDetailsInputSuspenseQuery
>;
export type GetProAgentDetailsInputQueryResult = Apollo.QueryResult<
  GetProAgentDetailsInputQuery,
  GetProAgentDetailsInputQueryVariables
>;
export const GetPayloadTemplatesDocument = gql`
  query GetPayloadTemplates($input: ProPayloadInput) {
    pro {
      agent {
        payload(input: $input) {
          templates {
            parent {
              ...GenericTemplate
            }
            popups {
              ...GenericTemplate
            }
            scenarios {
              ...ScenarioFields
            }
          }
        }
      }
    }
  }
  ${GenericTemplateFragmentDoc}
  ${ScenarioFieldsFragmentDoc}
`;

/**
 * __useGetPayloadTemplatesQuery__
 *
 * To run a query within a React component, call `useGetPayloadTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayloadTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayloadTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPayloadTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPayloadTemplatesQuery,
    GetPayloadTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPayloadTemplatesQuery, GetPayloadTemplatesQueryVariables>(
    GetPayloadTemplatesDocument,
    options,
  );
}
export function useGetPayloadTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPayloadTemplatesQuery,
    GetPayloadTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPayloadTemplatesQuery, GetPayloadTemplatesQueryVariables>(
    GetPayloadTemplatesDocument,
    options,
  );
}
export function useGetPayloadTemplatesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPayloadTemplatesQuery,
    GetPayloadTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPayloadTemplatesQuery,
    GetPayloadTemplatesQueryVariables
  >(GetPayloadTemplatesDocument, options);
}
export type GetPayloadTemplatesQueryHookResult = ReturnType<typeof useGetPayloadTemplatesQuery>;
export type GetPayloadTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetPayloadTemplatesLazyQuery
>;
export type GetPayloadTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useGetPayloadTemplatesSuspenseQuery
>;
export type GetPayloadTemplatesQueryResult = Apollo.QueryResult<
  GetPayloadTemplatesQuery,
  GetPayloadTemplatesQueryVariables
>;
