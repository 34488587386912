import { ColorTokenKey } from '@aily/ui-theme';
import { useAnimation } from 'framer-motion';
import { useEffect } from 'react';

interface UseValueComparisonBarProps {
  /**
   * Primary value shown as an outlined circle.
   */
  primaryValue: number;
  /**
   * Secondary value shown as a filled circle.
   */
  secondaryValue: number;
  /**
   * Reference value shown as a vertical bar.
   */
  baseValue: number;
  /**
   * Minimum value for clamping and normalization.
   */
  min: number;
  /**
   * Maximum value for clamping and normalization.
   */
  max: number;
}

/**
 * Hook used internally by the ValueComparisonBar component.
 * Handles clamping, normalization (with base centered at 50%),
 * directional logic, and animation state.
 */
export function useValueComparisonBar({
  primaryValue,
  secondaryValue,
  baseValue,
  min,
  max,
}: UseValueComparisonBarProps) {
  const clamp = (value: number) => Math.min(Math.max(value, min), max);
  const normalize = (value: number) => 50 + ((clamp(value) - baseValue) / (max - min)) * 100;

  const base = 50;
  const primary = normalize(primaryValue);
  const secondary = normalize(secondaryValue);

  const values = [primary, secondary];
  const minVal = Math.min(...values);
  const maxVal = Math.max(...values);
  const bothLeft = values.every((v) => v < base);
  const bothRight = values.every((v) => v > base);
  const split = !bothLeft && !bothRight;

  const color: ColorTokenKey = maxVal > base ? 'brand.green' : 'brand.pink';

  const primaryControls = useAnimation();
  const secondaryControls = useAnimation();
  const trackControlsLeft = useAnimation();
  const trackControlsRight = useAnimation();

  useEffect(() => {
    primaryControls.start({ left: `${primary}%` });
    secondaryControls.start({ left: `${secondary}%` });

    if (split) {
      trackControlsLeft.start({ width: `${Math.abs(base - minVal)}%` });
      trackControlsRight.start({ width: `${Math.abs(maxVal - base)}%` });
    } else if (bothLeft) {
      trackControlsLeft.start({ width: `${Math.abs(base - minVal)}%` });
    } else {
      trackControlsRight.start({ width: `${Math.abs(maxVal - base)}%` });
    }
  }, [
    primary,
    secondary,
    minVal,
    maxVal,
    base,
    split,
    bothLeft,
    primaryControls,
    secondaryControls,
    trackControlsLeft,
    trackControlsRight,
  ]);

  return {
    base,
    primary,
    secondary,
    color,
    split,
    bothLeft,
    bothRight,
    minVal,
    maxVal,
    primaryControls,
    secondaryControls,
    trackControlsLeft,
    trackControlsRight,
  };
}
