import { useCallback, useState } from 'react';

import { SegmentControlOption } from './SegmentControl';

interface UseSegmentControlProps<TValue> {
  defaultValue?: TValue;
  value?: TValue;
  onChange?: (newValue: TValue) => void;
  options?: SegmentControlOption<TValue>[];
}

export function useSegmentControl<TValue>({
  defaultValue,
  value,
  onChange,
  options,
}: UseSegmentControlProps<TValue>) {
  const [localValue, setLocalValue] = useState(defaultValue);
  const currentValue = value ?? localValue;
  const selectedIndex = options?.findIndex(({ value }) => value === currentValue) ?? -1;
  const currentIndex = selectedIndex !== -1 ? selectedIndex : 0;

  const handleChange = useCallback(
    (newValue: TValue) => {
      setLocalValue(newValue);
      onChange?.(newValue);
    },
    [onChange],
  );

  return { currentIndex, currentValue, handleChange };
}
