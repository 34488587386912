import { buttonClasses } from '@mui/base';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { FC, useCallback, useState } from 'react';

import { ButtonBase } from '../buttons';

export interface DotNavigationProps {
  /**
   * Total number of dots.
   */
  count: number;
  /**
   * Current active index (controlled). Optional.
   */
  index?: number;
  /**
   * Initial active index (uncontrolled). Optional.
   */
  defaultIndex?: number;
  /**
   * Callback function notifying when the controlled index value changes.
   *
   * @param index The new index value.
   */
  onIndexChanged?: (index: number) => void;
  /**
   * Optional styles to apply to the root element.
   */
  sx?: SxProps<Theme>;
  /**
   * Whether the navigation is disabled.
   */
  disabled?: boolean;
}

const DotNavigationRoot = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const Dot = styled('div')<{ active: boolean }>(({ active }) => ({
  width: 8,
  height: 8,
  borderRadius: 8,
  backgroundColor: 'currentColor',
  opacity: 0.5,
  transition: '200ms linear',
  transitionProperty: 'width, opacity',

  ...(active && {
    width: 16,
    opacity: 1,
  }),
}));

const DotButton = styled(ButtonBase)(({ theme }) => ({
  // Button padding spaces items (instead of using container gap) and expands the clickable area
  padding: theme.tokens.spacing['xxsmall'],
  color: theme.tokens.color['brand.aqua'],
  [`&.${buttonClasses.disabled}`]: {
    color: theme.tokens.color['text.disabled'],
  },
}));

const DotNavigation: FC<DotNavigationProps> = ({
  count,
  defaultIndex,
  index,
  onIndexChanged,
  sx,
  disabled = false,
}) => {
  const [localIndex, setLocalIndex] = useState(defaultIndex ?? 0);
  const currentIndex = index ?? localIndex;

  const isActive = useCallback((i: number) => i === currentIndex, [currentIndex]);

  const handleDotClick = (index: number) => {
    if (disabled) return;
    setLocalIndex(index);
    onIndexChanged?.(index);
  };

  return (
    <DotNavigationRoot role="navigation" sx={sx}>
      {[...Array(count).keys()].map((i) => {
        const active = isActive(i);
        return (
          <DotButton
            key={i}
            onClick={() => handleDotClick(i)}
            aria-current={active ? 'true' : undefined}
            disabled={disabled}
          >
            <Dot active={active} />
          </DotButton>
        );
      })}
    </DotNavigationRoot>
  );
};

export default DotNavigation;
