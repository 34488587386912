import { FC } from 'react';

export interface AilyLogoProps {
  width?: number;
  height?: number;
}

export const AilyLogo: FC<AilyLogoProps> = ({ width = 51, height = 31 }) => (
  <svg width={width} height={height} viewBox="0 0 51 31" fill="none">
    <path
      d="M34.1537 24.5569C33.572 24.6244 32.9344 24.6581 32.2408 24.6581C30.451 24.6581 29.0639 24.0847 28.0795 22.9378C27.1175 21.7909 26.6365 20.183 26.6365 18.1141V0.640712C26.6365 0.303389 26.8043 0.134728 27.1399 0.134728H30.0259C30.3615 0.134728 30.5293 0.303389 30.5293 0.640712V17.9791C30.5293 19.0811 30.7083 19.8794 31.0663 20.3741C31.4242 20.8464 31.9835 21.0825 32.7442 21.0825C33.0127 21.0825 33.4378 21.06 34.0195 21.015C34.355 21.015 34.5228 21.1837 34.5228 21.521V24.0172C34.5228 24.3545 34.3998 24.5345 34.1537 24.5569Z"
      fill="white"
    />
    <path
      d="M47.1072 19.5308V7.25225C47.1072 6.93742 47.275 6.78 47.6106 6.78H50.4967C50.8323 6.78 51.0001 6.93742 51.0001 7.25225V23.1402C51.0001 28.3799 48.5055 30.9998 43.5164 30.9998C40.8317 30.9998 38.6392 30.2914 36.9388 28.8747C36.6927 28.6948 36.6592 28.4699 36.8382 28.2L38.2812 26.1424C38.4602 25.85 38.6727 25.7938 38.9188 25.9737C40.4178 27.0082 41.9391 27.5254 43.4828 27.5254C44.7357 27.5254 45.6754 27.1881 46.3018 26.5134C46.9282 25.8613 47.2079 24.7368 47.1408 23.1402C45.8432 23.545 44.6462 23.7474 43.55 23.7474C41.6259 23.7474 39.948 23.1514 38.5161 21.9595C37.0843 20.7452 36.3683 19.0473 36.3683 16.866V7.25225C36.3683 6.93742 36.5361 6.78 36.8717 6.78H39.7913C40.1046 6.78 40.2612 6.93742 40.2612 7.25225V16.6973C40.2612 17.9117 40.6191 18.8 41.3351 19.3622C42.051 19.9244 42.9683 20.2055 44.0869 20.2055C45.2279 20.228 46.2347 20.0031 47.1072 19.5308Z"
      fill="white"
    />
    <path
      d="M1.04033 8.16323C2.76303 6.97135 4.95555 6.37541 7.6179 6.37541C9.78805 6.37541 11.4884 6.94886 12.7189 8.09576C13.9717 9.22017 14.5982 10.9293 14.5982 13.2231V23.0729C14.5982 23.3428 14.4751 23.5339 14.229 23.6464C12.0589 24.321 9.81042 24.6583 7.48366 24.6583C5.31351 24.6583 3.5237 24.2086 2.11422 23.309C0.70474 22.4095 0 20.9815 0 19.025C0 17.2485 0.659994 15.9104 1.97998 15.0109C3.32234 14.0889 5.11216 13.6279 7.34942 13.6279C8.24433 13.6279 9.36296 13.7178 10.7053 13.8977V13.2568C10.7053 12.0424 10.448 11.1766 9.93347 10.6594C9.44127 10.1197 8.57992 9.84984 7.34942 9.84984C5.69385 9.84984 4.262 10.2097 3.05387 10.9293C2.7854 11.1317 2.56167 11.0867 2.38269 10.7943L1.04033 8.73668C0.883721 8.4893 0.883721 8.29815 1.04033 8.16323ZM3.9264 18.9913C3.9264 19.7559 4.25081 20.3294 4.89962 20.7117C5.54842 21.0715 6.40977 21.2514 7.48366 21.2514C8.5128 21.2514 9.58669 21.1164 10.7053 20.8466V17.0348C9.4972 16.8549 8.41213 16.765 7.4501 16.765C5.10097 16.765 3.9264 17.5071 3.9264 18.9913Z"
      fill="#48FF9B"
    />
    <path
      d="M22.0325 24.2536H19.1129C18.7773 24.2536 18.6095 24.0849 18.6095 23.7476V7.25245C18.6095 6.93762 18.7773 6.7802 19.1129 6.7802H22.0325C22.3458 6.7802 22.5024 6.93762 22.5024 7.25245V23.7476C22.5024 24.0849 22.3458 24.2536 22.0325 24.2536ZM22.1332 0.640914C22.5583 1.0457 22.7708 1.56293 22.7708 2.1926C22.7708 2.82227 22.5583 3.35074 22.1332 3.77802C21.7305 4.2053 21.2159 4.41894 20.5895 4.41894C19.9631 4.41894 19.4373 4.2053 19.0122 3.77802C18.5872 3.35074 18.3746 2.82227 18.3746 2.1926C18.3746 1.56293 18.5872 1.0457 19.0122 0.640914C19.4373 0.213638 19.9631 0 20.5895 0C21.2159 0 21.7305 0.213638 22.1332 0.640914Z"
      fill="#48FF9B"
    />
  </svg>
);
