import { IconButton, IconButtonProps, SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

import { useSlideshow } from './SlideshowContext';

const PrevIcon = (props: SvgIconProps) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      d="M40,20C40,31.0446 31.0446,40 20,40C8.95536,40 0,31.0446 0,20C0,8.95536 8.95536,0 20,0C31.0446,0 40,8.95536 40,20Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.7773 12.0523V14.146C24.7773 14.6014 24.5586 15.0344 24.1881 15.3023L17.697 19.9987L24.1881 24.6951C24.5586 24.963 24.7773 25.3916 24.7773 25.8514V27.9451C24.7773 28.2353 24.447 28.405 24.2104 28.2353L13.2282 20.2889C13.0318 20.146 13.0318 19.8559 13.2282 19.713L24.2104 11.7666C24.447 11.5925 24.7773 11.7621 24.7773 12.0523Z"
      fill="#A6A6A6"
    />
  </SvgIcon>
);

export const SlideshowPrevButton: React.FC<IconButtonProps> = (props) => {
  const { state, dispatch } = useSlideshow();
  const theme = useTheme();

  const handleClick = () => {
    dispatch({ type: 'PREV_SLIDE' });
  };

  return (
    <IconButton
      onClick={handleClick}
      disabled={!state.hasPrevSlide}
      data-testid="slideshow__prev-button"
      {...props}
      sx={{
        position: 'absolute',
        zIndex: 1,
        opacity: 1,
        bottom: 'auto',
        top: '50%',
        left: theme.spacing(4),
        transform: 'translateY(-50%)',
        transition: 'opacity 0.3s',
        '&.Mui-disabled': {
          opacity: 0,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 40,
        },
      }}
    >
      <PrevIcon />
    </IconButton>
  );
};
