import * as T from '@aily/graphql-sdk/schema';
import { AgentBaseCard, AgentBaseCardVariant } from '@aily/ui-components';
import { Box, styled } from '@mui/material';
import React, { Suspense, useCallback } from 'react';

import { LoadingSkeleton } from '../../../..';
import { useAilyAgent } from '../../providers';
import Template from '../Template/Template';

export interface AgentSlideshowSlideSectionBuilderProps {
  templateData: T.ProAgentPayloadTemplatesResult;
  titleRef?: React.RefCallback<HTMLElement>;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

const SlideSectionBuilderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '342px',
  flex: 1,
  minWidth: 0,
  paddingLeft: 0,
}));

function getCardVariant(template: T.IProPayloadTemplateResult): AgentBaseCardVariant {
  if (T.isProPayloadTemplateLeversResult(template)) {
    return 'outlined';
  }

  return 'default';
}

export const AgentSlideshowSlideSectionBuilder: React.FC<
  AgentSlideshowSlideSectionBuilderProps
> = ({ templateData, titleRef }) => {
  const { setIsPopupOpen, setPopupData } = useAilyAgent();

  const handlePopupOpen = useCallback(
    (templateData: T.ProAgentPayloadTemplatesResult) => {
      setIsPopupOpen(true);
      setPopupData((templateData.popups ?? []) as T.IProPayloadTemplateResult[]);
    },
    [setIsPopupOpen, setPopupData],
  );

  if (!templateData.parent) {
    return null;
  }

  return (
    <SlideSectionBuilderContainer data-testid="agent-slideshow-slide-section-container">
      <AgentBaseCard variant={getCardVariant(templateData.parent)}>
        <AgentBaseCard.Header
          title={templateData.parent.title}
          subtitle={templateData.parent.subtitle ?? ''}
          titleRef={titleRef}
        />
        <AgentBaseCard.Content>
          <Suspense fallback={<LoadingSkeleton />}>
            <Template templateData={templateData.parent} />
          </Suspense>
        </AgentBaseCard.Content>
        <AgentBaseCard.Footer>
          <AgentBaseCard.Button onClick={() => handlePopupOpen(templateData)}>
            Open
          </AgentBaseCard.Button>
        </AgentBaseCard.Footer>
      </AgentBaseCard>
    </SlideSectionBuilderContainer>
  );
};
