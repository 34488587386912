import { FC } from 'react';

export interface DropShadowFilterProps {
  id: string;
  size: number;
  blurRadius?: number;
}

const DropShadowFilter: FC<DropShadowFilterProps> = ({ id, size, blurRadius = 2 }) => {
  const margin = blurRadius * 2;
  const filterSize = size + margin * 2;
  return (
    <defs>
      <filter
        id={id}
        x={-margin}
        y={-margin}
        width={filterSize}
        height={filterSize}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={blurRadius} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="DropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="DropShadow" result="shape" />
      </filter>
    </defs>
  );
};

export default DropShadowFilter;
