import { ColorTokenKey } from '@aily/ui-theme';
import React from 'react';

import { SvgIconProps, TrendDownIcon, TrendUpIcon } from '../icons';

export interface TrendIndicatorProps extends Omit<SvgIconProps, 'color'> {
  direction?: TrendIndicatorDirection;
  sentiment?: TrendIndicatorSentiment;
}

export type TrendIndicatorDirection = 'up' | 'down';
export type TrendIndicatorSentiment = 'positive' | 'negative';

const iconComponentMap: Record<TrendIndicatorDirection, React.ComponentType<SvgIconProps>> = {
  up: TrendUpIcon,
  down: TrendDownIcon,
};

const sentimentColorMap: Record<TrendIndicatorSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

const TrendIndicator: React.FC<TrendIndicatorProps> = ({
  direction = 'up',
  sentiment = 'positive',
  size = 'icon.xxsmall',
  ...rest
}) => {
  const IconComponent = iconComponentMap[direction];
  return (
    <IconComponent
      color={sentimentColorMap[sentiment]}
      size={size}
      data-testid="trend-indicator"
      data-x-direction={direction}
      data-x-sentiment={sentiment}
      {...rest}
    />
  );
};

export default TrendIndicator;
