import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

import { Typography } from '../../../../Typography';

export interface AgentCardHeaderProps {
  title: string;
  subtitle?: string;
  titleRef?: React.Ref<HTMLDivElement>;
}

const Root = styled('div')({
  height: 61,
  flexShrink: 0,
});

const AgentCardHeader: FC<AgentCardHeaderProps> = ({ title, subtitle, titleRef }) => (
  <Root>
    <Typography ref={titleRef} variant="heading.H8" lineClamp={1}>
      {title}
    </Typography>
    {subtitle && (
      <Typography variant="body.regular-condensed" color="text.accent" lineClamp={1}>
        {subtitle}
      </Typography>
    )}
  </Root>
);

export default AgentCardHeader;
