import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Typography } from '../../../../../../Typography';

export type IRFocusSentiment = 'positive' | 'negative';

export interface IRFocusRowProps {
  text: string;
  sentiment: IRFocusSentiment;
  sentimentText: string;
}

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const IRFocusRow: React.FC<IRFocusRowProps> = ({ text, sentiment, sentimentText }) => {
  return (
    <Container>
      <Typography variant="body.regular-condensed" color="text.subtle">
        {text}
      </Typography>
      <Typography
        variant="body.bold-condensed"
        color={sentiment === 'positive' ? 'text.success' : 'text.danger'}
      >
        {sentimentText}
      </Typography>
    </Container>
  );
};
