import { useLayoutEffect, useRef, useState } from 'react';

/**
 * Provides horizontal drag-to-scroll behavior for a scrollable container.
 *
 * @returns An object containing:
 * - `scrollRef`: ref to attach to the scroll container.
 * - `isGrabbing`: whether the user is actively dragging.
 * - `isScrollable`: whether horizontal scrolling is possible.
 * - `eventHandlers`: mouse event handlers to spread onto the scroll container.
 */
export const useHorizontalDragScroll = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const onMouseDown = (e: React.MouseEvent) => {
    if (!scrollRef.current) return;
    setIsGrabbing(true);
    startX.current = e.pageX;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const onMouseUpOrLeave = () => {
    setIsGrabbing(false);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isGrabbing || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX;
    const delta = x - startX.current;
    scrollRef.current.scrollLeft = scrollLeft.current - delta;
  };

  // Auto-detect scrollability with ResizeObserver
  useLayoutEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    const observer = new ResizeObserver(() => {
      setIsScrollable(el.scrollWidth > el.clientWidth);
    });

    observer.observe(el);
    setIsScrollable(el.scrollWidth > el.clientWidth);

    return () => observer.disconnect();
  }, []);

  return {
    scrollRef,
    isGrabbing,
    isScrollable,
    eventHandlers: {
      onMouseDown,
      onMouseUp: onMouseUpOrLeave,
      onMouseLeave: onMouseUpOrLeave,
      onMouseMove,
    },
  };
};
