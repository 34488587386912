import { buttonClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';

import {
  AiIcon,
  CloseIcon,
  DotSeparator,
  SendIcon,
  Stack,
  Typography,
} from '../../../../components';
import ButtonBase from '../../../../components/buttons/ButtonBase/ButtonBase';
import AgentHeaderButtons, { AgentHeaderButtonsProps } from './AgentHeaderButtons';

/**
 * Discriminated union type for strict prop validation based on `variant`.
 * This is how variants and their constraints are defined in Figma.
 */
export type AgentHeaderProps =
  | {
      variant: 'one-line';
      title: string;
      subtitle?: string;
      onShareButtonClick?: () => void;
      onCloseButtonClick?: () => void;
      buttons?: React.ReactElement<AgentHeaderButtonsProps>;
    }
  | {
      variant: 'two-line';
      title: React.ReactNode;
      subtitle?: never; // Disallowed
      onShareButtonClick?: () => void;
      onCloseButtonClick?: () => void;
      buttons?: React.ReactElement<AgentHeaderButtonsProps>;
    }
  | {
      variant: 'icons-only';
      title?: never; // Disallowed
      subtitle?: never; // Disallowed
      onShareButtonClick?: () => void;
      onCloseButtonClick?: () => void;
      buttons?: never; // Disallowed
    };

export type AgentHeaderVariant = 'one-line' | 'two-line' | 'icons-only';

const AgentHeaderRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['xsmall'],
  width: '100%',
  height: 184,
  padding: theme.spacing(theme.tokens.spacing['xxlarge'], theme.tokens.spacing['huge']),
  paddingBottom: 0,
}));

const AgentHeaderToolbar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  height: 28,
});

const AgentHeaderIconButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.tokens.spacing['xsmall'],
}));

const IconButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.tokens.color['text.regular'],
  [`&.${buttonClasses.disabled}`]: {
    color: theme.tokens.color['text.disabled'],
  },
}));

const AgentHeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['large'],
}));

interface AgentHeaderComponent extends React.FC<AgentHeaderProps> {
  Buttons: typeof AgentHeaderButtons;
}

export const AgentHeader: AgentHeaderComponent = ({
  variant,
  title,
  subtitle,
  onShareButtonClick,
  onCloseButtonClick,
  buttons,
}) => {
  return (
    <AgentHeaderRoot data-testid="agent-header">
      <AgentHeaderToolbar>
        <AgentHeaderIconButtons>
          {onShareButtonClick && (
            <IconButton onClick={onShareButtonClick} data-testid="agent-header__share-button">
              <SendIcon size="icon.xsmall" />
            </IconButton>
          )}
          <IconButton
            onClick={onCloseButtonClick}
            disabled={!onCloseButtonClick}
            data-testid="agent-header__close-button"
          >
            <CloseIcon size="icon.medium" />
          </IconButton>
        </AgentHeaderIconButtons>
      </AgentHeaderToolbar>

      {variant !== 'icons-only' && (
        <AgentHeaderContent>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing="medium">
            <AiIcon size="icon.medium-large" />
            <Stack direction="row" alignItems="center" divider={<DotSeparator />} spacing="small">
              <Typography
                variant="heading.H7"
                lineClamp={variant === 'two-line' ? 2 : 1}
                sx={{ '& > *': { display: 'inline' } }}
                data-testid="agent-header__title"
              >
                {title}
              </Typography>

              {variant === 'one-line' && subtitle && (
                <Typography
                  variant="heading.H6"
                  color="text.subtle"
                  lineClamp={1}
                  data-testid="agent-header__subtitle"
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Stack>

          {buttons}
        </AgentHeaderContent>
      )}
    </AgentHeaderRoot>
  );
};

AgentHeader.Buttons = AgentHeaderButtons;
