import { alpha, styled } from '@mui/material/styles';
import React, { FC } from 'react';

import AgentCardButton from './AgentCardButton';
import AgentCardContent from './AgentCardContent';
import AgentCardFooter from './AgentCardFooter';
import AgentCardHeader from './AgentCardHeader';

export interface AgentBaseCardProps {
  children?: React.ReactNode;
  variant?: AgentBaseCardVariant;
}

export type AgentBaseCardVariant = 'default' | 'outlined';

const Root = styled('div')<{ $variant: AgentBaseCardVariant }>(({ theme, $variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 520,
  width: 342,
  padding: theme.tokens.spacing.xxlarge,
  borderRadius: theme.tokens.borderRadius.medium,

  ...($variant === 'default' && {
    backgroundColor: alpha(theme.tokens.color['neutral.grey-darker'], 0.35),
  }),

  ...($variant === 'outlined' && {
    border: `1px solid ${theme.tokens.color['neutral.grey']}`,
    backdropFilter: 'blur(25px)',
  }),
}));

type AgentBaseCardComponent = FC<AgentBaseCardProps> & {
  Button: typeof AgentCardButton;
  Content: typeof AgentCardContent;
  Footer: typeof AgentCardFooter;
  Header: typeof AgentCardHeader;
};

const AgentBaseCard: AgentBaseCardComponent = ({ children, variant = 'default' }) => (
  <Root $variant={variant}>{children}</Root>
);

AgentBaseCard.Button = AgentCardButton;
AgentBaseCard.Content = AgentCardContent;
AgentBaseCard.Footer = AgentCardFooter;
AgentBaseCard.Header = AgentCardHeader;

export default AgentBaseCard;
