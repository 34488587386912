import { GetPayloadTemplatesDocument } from '@aily/graphql-sdk/modules/pro';
import {
  ProAgentPayloadTemplatesResult,
  ProAgentStructureResult,
  ProPayloadTemplatesInput,
  TemplateType,
} from '@aily/graphql-sdk/schema';
import { ApolloError, useLazyQuery } from '@aily/saas-graphql-client';
import { compact, filter, find, map } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ProQueryAbstractType } from './AgentStructureContext';

export interface Template {
  is_recommended?: boolean;
  screenIds?: string[];
  id?: string;
  [key: string]: unknown;
}

export interface AgentDataContextValue {
  agentIntroData: ProAgentPayloadTemplatesResult[];
  isAgentIntroDataLoading: boolean;
  agentTemplatesData: ProAgentPayloadTemplatesResult[];
  isAgentTemplatesDataLoading: boolean;
  agentLeversScenario: Template[];
  setAgentLeversScenario: (scenario: Template[]) => void;
}

export interface AgentDataContextProviderProps {
  children: ReactNode;
  agentStructure?: ProAgentStructureResult;
}

const AgentDataContext = createContext<AgentDataContextValue | undefined>(undefined);

export const AgentDataContextProvider = ({
  children,
  agentStructure,
}: AgentDataContextProviderProps) => {
  const { id: agentId, templates: agentTemplates } = agentStructure ?? {};
  const [agentIntroData, setAgentIntroData] = useState<ProAgentPayloadTemplatesResult[]>([]);
  const [isAgentIntroDataLoading, setIsAgentIntroDataLoading] = useState(false);

  const [agentTemplatesData, setAgentTemplatesData] = useState<ProAgentPayloadTemplatesResult[]>(
    [],
  );
  const [isAgentTemplatesDataLoading, setIsAgentTemplatesDataLoading] = useState(false);

  const [agentLeversScenario, setAgentLeversScenario] = useState<Template[]>([]);

  // Single lazy query to fetch data for both intro and additional templates
  const [fetchData] = useLazyQuery<ProQueryAbstractType>(GetPayloadTemplatesDocument);

  // Fetch the additional (non-intro) templates
  const handleFetchAgentTemplatesDataComplete = useCallback((data: ProQueryAbstractType) => {
    const templates = data?.pro?.agent?.payload?.templates ?? [];
    const filteredTemplates = filter(
      templates,
      (template) => template.parent?.templateType !== 'ERROR',
    );
    setAgentTemplatesData(filteredTemplates);
    setIsAgentTemplatesDataLoading(false);
  }, []);

  const handleFetchAgentTemplatesDataError = useCallback((error: ApolloError) => {
    console.error('Error fetching agent templates data:', error);
    setIsAgentTemplatesDataLoading(false);
  }, []);

  const fetchAgentTemplatesData = useCallback(() => {
    if (!agentId || !agentTemplates?.length) return;

    const filteredTemplates = filter(
      agentTemplates,
      (template) => template.templateType !== TemplateType.Intro,
    );

    if (!filteredTemplates.length) return;

    const templateVariables: ProPayloadTemplatesInput[] = map(filteredTemplates, (template) => {
      const input: ProPayloadTemplatesInput = {
        parent: {
          id: template.templateId ?? '',
          type: template.templateType,
        },
      };

      if (template.popups && template.popups.length) {
        input.popups = compact(
          map(template.popups, (popup) => {
            if (popup?.templateId && popup?.templateType) {
              return { id: popup.templateId, type: popup.templateType };
            }
          }),
        );
      }

      if (template.scenarios) {
        input.scenarios = compact(
          map(template.scenarios, (scenario) => {
            if (scenario.scenarioTemplates) {
              return {
                scope: scenario.leversScope,
                combinations: compact(
                  map(scenario.combinations ?? [], (combination) => {
                    if (combination?.key && combination?.value) {
                      return {
                        key: combination.key,
                        value: combination.value,
                      };
                    }
                  }),
                ),
                templates: compact(
                  map(scenario.scenarioTemplates, (template) => {
                    if (template && template?.templateId && template?.templateType) {
                      return {
                        id: template.templateId,
                        type: template.templateType,
                      };
                    }
                  }),
                ),
              };
            }
          }),
        );
      }

      return input;
    });

    setIsAgentTemplatesDataLoading(true);

    fetchData({
      variables: {
        input: {
          agentId,
          templates: templateVariables,
        },
      },
      onCompleted: handleFetchAgentTemplatesDataComplete,
      onError: handleFetchAgentTemplatesDataError,
    });
  }, [
    agentId,
    agentTemplates,
    fetchData,
    handleFetchAgentTemplatesDataComplete,
    handleFetchAgentTemplatesDataError,
  ]);

  // Fetch the intro template
  const handleFetchAgentIntroDataComplete = useCallback(
    (data: ProQueryAbstractType) => {
      const templates = data?.pro?.agent?.payload?.templates ?? [];
      setAgentIntroData(templates);
      setIsAgentIntroDataLoading(false);

      // Immediately trigger the fetch for additional templates once intro is fetched
      if (templates.length > 0) {
        fetchAgentTemplatesData();
      }
    },
    [fetchAgentTemplatesData],
  );

  const handleFetchAgentIntroDataError = useCallback((error: ApolloError) => {
    console.error('Error fetching agent intro data:', error);
    setIsAgentIntroDataLoading(false);
  }, []);

  const fetchAgentIntroData = useCallback(() => {
    if (!agentId || !agentTemplates?.length) return;

    const introTemplate = find(
      agentTemplates,
      (template) => template.templateType === TemplateType.Intro,
    );

    if (!introTemplate) return;

    setIsAgentIntroDataLoading(true);

    fetchData({
      variables: {
        input: {
          agentId,
          templates: [
            {
              parent: {
                id: introTemplate.templateId,
                type: introTemplate.templateType,
              },
            },
          ],
        },
      },
      onCompleted: handleFetchAgentIntroDataComplete,
      onError: handleFetchAgentIntroDataError,
    });
  }, [
    agentId,
    agentTemplates,
    fetchData,
    handleFetchAgentIntroDataComplete,
    handleFetchAgentIntroDataError,
  ]);

  // Fire the intro data fetch when the component mounts
  useEffect(() => {
    fetchAgentIntroData();
  }, [fetchAgentIntroData]);

  const contextValue = useMemo<AgentDataContextValue>(
    () => ({
      agentIntroData,
      isAgentIntroDataLoading,
      agentTemplatesData,
      isAgentTemplatesDataLoading,
      agentLeversScenario,
      setAgentLeversScenario,
    }),
    [
      agentIntroData,
      isAgentIntroDataLoading,
      agentTemplatesData,
      isAgentTemplatesDataLoading,
      agentLeversScenario,
      setAgentLeversScenario,
    ],
  );

  return <AgentDataContext.Provider value={contextValue}>{children}</AgentDataContext.Provider>;
};

export const useAgentDataContext = () => {
  const context = useContext(AgentDataContext);
  if (!context) {
    throw new Error('useAgentDataContext must be used within a AgentDataContextProvider');
  }

  return context;
};
