import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { map } from 'lodash-es';
import React from 'react';

import { ScrollableContainer } from '../../../../ScrollableContainer';
import { Stack } from '../../../../Stack';
import { HorizontalSeparator } from '../../../commons/utils/HorizontalSeparator';
import { ChartHorizontalBarRow, ChartHorizontalBarRowProps, PopupSentimentTitle } from './internal';

export interface GetMoneyTemplateItem extends Omit<ChartHorizontalBarRowProps, 'sentiment'> {
  title: string;
  subtitle: string;
  percentage?: number;
  sentiment: 'positive' | 'negative';
}

export interface GetMoneyTemplateProps {
  leftHeader: {
    title: string;
    subtitle: string;
    sentiment: 'positive' | 'negative';
  };
  rightHeader: {
    title: string;
    subtitle: string;
    sentiment: 'positive' | 'negative';
  };
  leftItems: GetMoneyTemplateItem[];
  rightItems: GetMoneyTemplateItem[];
}

const Container = styled(Stack)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
});

const VerticalDivider = styled(Box)(({ theme }) => ({
  width: 2,
  marginLeft: theme.tokens.spacing.xxxlarge,
  marginRight: theme.tokens.spacing.xxxlarge,
  background: `linear-gradient(
    90deg,
    ${alpha(theme.tokens.color['brand.mint'], 0)} 0%,
    ${theme.tokens.color['brand.mint']} 50.5%,
    ${alpha(theme.tokens.color['brand.mint'], 0)} 100%
  )`,
  boxShadow: `0 0 10px ${theme.tokens.color['brand.mint']}, 0 0 20px ${theme.tokens.color['brand.mint']}, 0 0 30px ${theme.tokens.color['brand.mint']}`,
  opacity: 0.6,
}));

const Column = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.tokens.spacing.medium,
  display: 'flex',
  flexDirection: 'column',
  height: 400,
}));

const ItemsList = styled(Stack)(({ theme }) => ({
  gap: theme.tokens.spacing.small,
  minHeight: 0,
  flex: 1,
}));

const StyledScrollableContainer = styled(ScrollableContainer)({
  flex: 1,
  minHeight: 0,
  maxHeight: 300,
});

export const GetMoneyTemplate: React.FC<GetMoneyTemplateProps> = ({
  leftHeader,
  rightHeader,
  leftItems,
  rightItems,
}) => {
  return (
    <Container divider={<VerticalDivider />}>
      <Column>
        <PopupSentimentTitle
          title={leftHeader.title}
          subtitle={leftHeader.subtitle}
          sentiment={leftHeader.sentiment}
        />
        <StyledScrollableContainer>
          <ItemsList divider={<HorizontalSeparator />}>
            {map(leftItems, (item, index) => (
              <ChartHorizontalBarRow key={index} {...item} />
            ))}
          </ItemsList>
        </StyledScrollableContainer>
      </Column>
      <Column>
        <PopupSentimentTitle
          title={rightHeader.title}
          subtitle={rightHeader.subtitle}
          sentiment={rightHeader.sentiment}
        />
        <StyledScrollableContainer>
          <ItemsList divider={<HorizontalSeparator />}>
            {map(rightItems, (item, index) => (
              <ChartHorizontalBarRow key={index} {...item} />
            ))}
          </ItemsList>
        </StyledScrollableContainer>
      </Column>
    </Container>
  );
};

export default GetMoneyTemplate;
