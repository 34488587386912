import { ColorTokenKey } from '@aily/ui-theme';
import React from 'react';

import { Typography, TypographyProps } from '../../../../../../Typography';

export type DualSentimentType = 'positive' | 'negative';

//This is meant to be a general-proposed common component soon , but it is still defined in FIGMA as agent-related internal component.
//As soon they define it as a common component, we should move it a common folder and use it from there.
export interface H4DualProps extends Omit<TypographyProps, 'variant' | 'color' | 'sx'> {
  sentiment?: DualSentimentType;
  value: number | string;
}

const sentimentColorMap: Record<DualSentimentType, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
} as const;

export const H4Dual: React.FC<H4DualProps> = ({ sentiment = 'positive', value, ...rest }) => (
  <Typography variant="heading.H4" color={sentimentColorMap[sentiment]} {...rest}>
    {value}
  </Typography>
);

export default H4Dual;
