import type { IProPayloadTemplateResult } from '@aily/graphql-sdk/schema';
import { FC, useCallback, useMemo, useRef } from 'react';

import { urlConcat } from '../../../../utils';
import { AilyAgentOperation } from '../../classes';
import { useAilyAgentOperations } from '../../hooks';
import { useAilyAgent } from '../../providers';
import Template from '../Template/Template';

interface AgentIntroSlideTemplateProps {
  template: IProPayloadTemplateResult;
}

const AgentIntroSlideTemplate: FC<AgentIntroSlideTemplateProps> = ({ template }) => {
  const { baseAudioURL } = useAilyAgent();
  const ref = useRef<HTMLElement | null>(null);

  const memoizedTemplate = useMemo(() => [template], [template]);

  const mapToOperation = useCallback(
    (item: IProPayloadTemplateResult, element: HTMLElement): AilyAgentOperation => ({
      audioPath: urlConcat(baseAudioURL, item.audio?.audioPathEncoded ?? ''),
      elementRef: element,
      delayBeforeStart: 1000,
      delayAfterEnd: 1000,
      corner: 'top-right',
    }),
    [baseAudioURL],
  );

  const { setRef } = useAilyAgentOperations(memoizedTemplate, true, mapToOperation);

  const handleRef = useCallback(
    (node: HTMLElement | null) => {
      ref.current = node;
      setRef(0)(node);
    },
    [setRef],
  );

  return (
    <>
      <div ref={handleRef} />
      <Template templateData={template} />
    </>
  );
};

export default AgentIntroSlideTemplate;
