import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { AgentSlider, AgentSliderProps } from '../../../others/AgentSlider';
import { MoneyBox, MoneyBoxProps } from './internal/MoneyBox';
import {
  OptimisationScopeSelect,
  OptimisationScopeSelectProps,
} from './internal/OptimisationScopeSelect';

export interface LeversTemplateProps {
  defaultOptimisationScope?: OptimisationScopeSelectProps['defaultValue'];
  optimisationScope?: OptimisationScopeSelectProps['value'];
  onOptimisationScopeChange?: OptimisationScopeSelectProps['onChange'];
  optimisationScopeOptions?: OptimisationScopeSelectProps['options'];
  moneyBoxTitle?: MoneyBoxProps['title'];
  moneyBoxItems?: MoneyBoxProps['items'];
  primarySliderTitle: AgentSliderProps['title'];
  primarySliderValue: AgentSliderProps['value'];
  primarySliderDefaultValue?: AgentSliderProps['defaultValue'];
  primarySliderOptions: AgentSliderProps['options'];
  onPrimarySliderChange: AgentSliderProps['onChange'];
  secondarySliderTitle?: AgentSliderProps['title'];
  secondarySliderValue?: AgentSliderProps['value'];
  secondarySliderDefaultValue?: AgentSliderProps['defaultValue'];
  secondarySliderOptions?: AgentSliderProps['options'];
  onSecondarySliderChange?: AgentSliderProps['onChange'];
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['xlarge'],
}));

export const LeversTemplate: FC<LeversTemplateProps> = ({
  defaultOptimisationScope,
  optimisationScope,
  onOptimisationScopeChange,
  optimisationScopeOptions,
  moneyBoxTitle,
  moneyBoxItems,
  primarySliderTitle,
  primarySliderValue,
  primarySliderDefaultValue,
  primarySliderOptions,
  onPrimarySliderChange,
  secondarySliderTitle,
  secondarySliderValue,
  secondarySliderDefaultValue,
  secondarySliderOptions,
  onSecondarySliderChange,
}) => (
  <Root>
    <OptimisationScopeSelect
      defaultValue={defaultOptimisationScope}
      value={optimisationScope}
      onChange={onOptimisationScopeChange}
      options={optimisationScopeOptions}
    />
    {moneyBoxTitle && moneyBoxItems?.length && (
      <MoneyBox title={moneyBoxTitle} items={moneyBoxItems} />
    )}
    <AgentSlider
      title={primarySliderTitle}
      defaultValue={primarySliderDefaultValue}
      value={primarySliderValue}
      options={primarySliderOptions}
      onChange={onPrimarySliderChange}
    />
    {secondarySliderTitle && secondarySliderOptions?.length && (
      <AgentSlider
        title={secondarySliderTitle}
        defaultValue={secondarySliderDefaultValue}
        value={secondarySliderValue}
        options={secondarySliderOptions}
        onChange={onSecondarySliderChange}
      />
    )}
  </Root>
);
