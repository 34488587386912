import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material/styles';
import React from 'react';

import { IconName, icons } from '../../../../../../icons';
import { Typography } from '../../../../../../Typography';

export type ContentRowIconName = Extract<
  IconName,
  'plusCircle' | 'minusCircle' | 'flash' | 'exclamationCircle'
>;

export interface ContentRowIconProps {
  iconName: ContentRowIconName;
  title: string;
  description: string;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  paddingBottom: theme.tokens.spacing.xsmall,
}));

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xxsmall,
}));

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexShrink: 0,
  width: 28,
  paddingTop: theme.tokens.spacing.xxsmall,
}));

const iconColorMap: Record<ContentRowIconName, ColorTokenKey> = {
  plusCircle: 'gradient.mint.vertical',
  minusCircle: 'gradient.pink.vertical',
  flash: 'gradient.blue.vertical',
  exclamationCircle: 'gradient.orange.vertical',
};

export const ContentRowIcon: React.FC<ContentRowIconProps> = ({ iconName, title, description }) => {
  const IconComponent = icons[iconName];

  return (
    <Container>
      <IconContainer>
        <IconComponent size="icon.xxsmall" color={iconColorMap[iconName]} />
      </IconContainer>
      <ContentContainer>
        <Typography variant="body.regular-condensed" lineClamp={6}>
          {title}
        </Typography>
        {description && (
          <Typography variant="body.regular-condensed" color="text.subtle">
            {description}
          </Typography>
        )}
      </ContentContainer>
    </Container>
  );
};
