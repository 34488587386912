import type {
  IProPayloadTemplateResult,
  ProAgentPayloadTemplatesResult,
} from '@aily/graphql-sdk/schema';
import { AgentBaseCard, SliderCarousel, SliderCarouselProvider } from '@aily/ui-components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSlideshow } from '../../../../components';
import { urlConcat } from '../../../../utils';
import { AilyAgentEventData, AilyAgentOperation } from '../../classes';
import { useAilyAgentOperations, useAilyAgentPlaybackStatus } from '../../hooks';
import { useAilyAgent } from '../../providers';
import Template from '../Template/Template';
import { AgentSlideshowSlideSectionBuilder } from './AgentSlideshowSlideSectionBuilder';

interface AgentSlideshowSlideContentProps {
  templatesData: ProAgentPayloadTemplatesResult[];
  isCarouselContent?: boolean;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

export const AgentSlideshowSlideContent: FC<AgentSlideshowSlideContentProps> = ({
  templatesData,
  isCarouselContent = false,
}) => {
  const { agent, baseAudioURL } = useAilyAgent();
  const { state } = useSlideshow();
  const { audioEnabled } = state;

  const status = useAilyAgentPlaybackStatus();
  const isAgentPlaying = status === 'playing';

  const memoizedTemplates = useMemo(() => templatesData, [templatesData]);
  const [slideIndex, setSlideIndex] = useState(0);

  const mapToOperation = useCallback(
    (template: ProAgentPayloadTemplatesResult, ref: HTMLElement): AilyAgentOperation => {
      const audioPath = template.parent?.audio?.audioPathEncoded;
      return {
        audioPath: audioPath ? urlConcat(baseAudioURL, audioPath) : '',
        elementRef: ref,
        delayBeforeStart: 1000,
        delayAfterEnd: 1000,
        corner: 'top-right',
      };
    },
    [baseAudioURL],
  );

  const handleStep = useCallback((index: number) => {
    setSlideIndex(index);
  }, []);

  const { setRef } = useAilyAgentOperations(
    memoizedTemplates,
    audioEnabled,
    mapToOperation,
    handleStep,
  );

  // Auto sync slide index when agent resumes from pause
  useEffect(() => {
    const handlePlay = (data: AilyAgentEventData) => {
      if (data.index !== null) setSlideIndex(data.index);
    };

    agent.addEventListener('play', handlePlay);
    return () => agent.removeEventListener('play', handlePlay);
  }, [agent]);

  if (isCarouselContent) {
    return (
      <AgentBaseCard>
        <SliderCarouselProvider
          totalSlides={memoizedTemplates.length}
          index={slideIndex}
          onIndexChange={setSlideIndex}
        >
          <SliderCarousel disableDragging={isAgentPlaying}>
            {memoizedTemplates.map((templateData, index) => (
              <SliderCarousel.Slide key={index}>
                <AgentBaseCard.Header
                  title={templateData.parent?.title ?? ''}
                  subtitle={templateData.parent?.subtitle ?? ''}
                  titleRef={setRef(index)}
                />
                <AgentBaseCard.Content>
                  <Template templateData={templateData.parent as IProPayloadTemplateResult} />
                </AgentBaseCard.Content>
              </SliderCarousel.Slide>
            ))}
          </SliderCarousel>
          <AgentBaseCard.Footer>
            <SliderCarousel.Navigation disabled={isAgentPlaying} />
          </AgentBaseCard.Footer>
        </SliderCarouselProvider>
      </AgentBaseCard>
    );
  }

  return memoizedTemplates.map((templateData, index) => (
    <AgentSlideshowSlideSectionBuilder
      key={index}
      titleRef={setRef(index)}
      templateData={templateData}
    />
  ));
};
