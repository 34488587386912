import { Box, BoxProps, styled } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';

import { useSlideshow } from './SlideshowContext';
import { SlideshowNextButton } from './SlideshowNextButton';
import { SlideShowPlayButton } from './SlideShowPlayButton';
import { SlideshowPrevButton } from './SlideshowPrevButton';
import { SlideshowScreen, SlideshowScreenProps } from './SlideshowScreen';
import { SlideshowSlide } from './SlideshowSlide';
import { SlideshowSlideGroup } from './SlideshowSlideGroup';
import { computeStructure } from './utils';

export interface SlideshowProps extends BoxProps {
  children: React.ReactElement<SlideshowScreenProps> | React.ReactElement<SlideshowScreenProps>[];
  SlideshowContentProps?: BoxProps;
}

type SlideshowComponents = {
  NextButton: typeof SlideshowNextButton;
  PlayButton: typeof SlideShowPlayButton;
  PrevButton: typeof SlideshowPrevButton;
  Screen: typeof SlideshowScreen;
  Slide: typeof SlideshowSlide;
  SlideGroup: typeof SlideshowSlideGroup;
};

const SlideshowContainer = styled(Box)(() => ({
  position: 'relative',
  overflow: 'auto',
  maxWidth: 1360,
  height: 834,
  gap: 24,
  margin: 'auto',
  paddingTop: 0,
  paddingBottom: 100,
}));

const SlideshowContent = styled(Box)(() => ({
  position: 'relative',
  height: '100%',
}));

/**
 * Provides a hierarchical slideshow navigation structure.
 *
 * The `Slideshow` component provides a hierarchical structure consisting of:
 * - `SlideshowProvider` - A context provider managing the slideshow state and navigation actions.
 * - `Slideshow` - The main container encapsulating the entire slideshow.
 *   - `Slideshow.Screen` - Defines individual screens, each identified by an index, and containing one or more slide groups.
 *   - `Slideshow.SlideGroup` - Represents a group of slides within a screen, identified by an index and containing multiple slides.
 *   - `Slideshow.Slide` - Individual slides within a slide group, each having its own index and content.
 *   - `Slideshow.PrevButton` - Navigates to the previous slide, group, or screen.
 *   - `Slideshow.NextButton` - Navigates to the next slide, group, or screen.
 *
 * This component structure allows for flexible and hierarchical slideshow navigation, managing screens, groups, and slides.
 */
export const Slideshow: React.FC<SlideshowProps> & SlideshowComponents = ({
  children,
  SlideshowContentProps,
  ...rest
}) => {
  const { dispatch } = useSlideshow();

  useEffect(() => {
    dispatch({ type: 'SET_STRUCTURE', payload: { structure: computeStructure(children) } });
  }, [children, dispatch]);

  return (
    <SlideshowContainer data-testid="slideshow" {...rest}>
      <SlideshowContent data-testid="slideshow__content" {...SlideshowContentProps}>
        <AnimatePresence mode="wait" initial={false}>
          {children}
        </AnimatePresence>
      </SlideshowContent>
    </SlideshowContainer>
  );
};

Slideshow.PlayButton = SlideShowPlayButton;
Slideshow.NextButton = SlideshowNextButton;
Slideshow.PrevButton = SlideshowPrevButton;
Slideshow.Screen = SlideshowScreen;
Slideshow.Slide = SlideshowSlide;
Slideshow.SlideGroup = SlideshowSlideGroup;
