import { ColorTokenKey } from '@aily/ui-theme';
import { useTheme } from '@mui/material';
import React from 'react';

export interface DotSeparatorProps {
  color?: ColorTokenKey;
}

const DotSeparator: React.FC<DotSeparatorProps> = ({ color = 'neutral.200' }) => {
  const theme = useTheme();
  return (
    <svg width="5" height="5" viewBox="0 0 5 5" fill="none">
      <circle cx="2.5" cy="2" r="2" fill={theme.tokens.color[color]} />
    </svg>
  );
};

export default DotSeparator;
