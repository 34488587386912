import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import SliderCarouselNavigation from './SliderCarouselNavigation';
import { useSliderCarousel } from './SliderCarouselProvider';
import SliderCarouselSlide from './SliderCarouselSlide';

export interface SliderCarouselProps {
  children: ReactNode[];
  disableDragging?: boolean;
}

const Root = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
});

const ScrollContainer = styled('div')<{
  $isGrabbing: boolean;
  $disableDragging: boolean;
}>(({ $isGrabbing, $disableDragging }) => ({
  display: 'flex',
  flex: 1,
  userSelect: 'none',
  cursor: $disableDragging ? 'default' : $isGrabbing ? 'grabbing' : 'grab',
}));

type SliderCarouselComponent = React.FC<SliderCarouselProps> & {
  Slide: typeof SliderCarouselSlide;
  Navigation: typeof SliderCarouselNavigation;
};

const SliderCarousel: SliderCarouselComponent = ({ children, disableDragging = false }) => {
  const { containerRef, isGrabbing, handleDragStart, handleDragMove, handleDragEnd } =
    useSliderCarousel();

  const onMouseDown = (e: React.MouseEvent) => {
    if (disableDragging) return;
    handleDragStart(e.clientX);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (disableDragging) return;
    handleDragMove(e.clientX);
  };

  const onMouseUp = () => {
    if (disableDragging) return;
    handleDragEnd();
  };

  const onMouseLeave = () => {
    if (disableDragging) return;
    handleDragEnd();
  };

  return (
    <Root>
      <ScrollContainer
        ref={containerRef}
        $isGrabbing={isGrabbing}
        $disableDragging={disableDragging}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </ScrollContainer>
    </Root>
  );
};

SliderCarousel.Slide = SliderCarouselSlide;
SliderCarousel.Navigation = SliderCarouselNavigation;

export default SliderCarousel;
