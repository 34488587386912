import { styled } from '@mui/material/styles';
import React from 'react';

import { ButtonBase } from '../../../../buttons';
import { Typography } from '../../../../Typography';

export interface AgentCardButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: AgentCardButtonVariant;
}

export type AgentCardButtonVariant = 'dark' | 'light';

export const ButtonRoot = styled(ButtonBase)<{ $variant: AgentCardButtonVariant }>(
  ({ theme, $variant }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.tokens.borderRadius['large'],
    minWidth: 153,
    height: 38,

    ...($variant === 'dark' && {
      backgroundColor: theme.tokens.color['neutral.grey-darker'],
      color: theme.tokens.color['text.regular'],
    }),

    ...($variant === 'light' && {
      backgroundColor: theme.tokens.color['neutral.white'],
      color: theme.tokens.color['neutral.black'],
    }),
  }),
);

const AgentCardButton: React.FC<AgentCardButtonProps> = ({
  children,
  onClick,
  variant = 'dark',
}) => (
  <ButtonRoot $variant={variant} onClick={onClick}>
    <Typography variant="heading.button" lineClamp={1}>
      {children}
    </Typography>
  </ButtonRoot>
);

export default AgentCardButton;
