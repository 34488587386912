import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type * as T from '../../../../schema/__generated__/types';
const defaultOptions = {} as const;
export type ProAgentTakeDecisionMutationMutationVariables = T.Exact<{
  input: T.ProAgentTakeDecisionInput;
}>;

export type ProAgentTakeDecisionMutationMutation = {
  __typename?: 'Mutation';
  proAgentTakeDecision: boolean;
};

export type ProAgentRevertDecisionMutationMutationVariables = T.Exact<{
  input: T.ProAgentRevertDecisionInput;
}>;

export type ProAgentRevertDecisionMutationMutation = {
  __typename?: 'Mutation';
  proAgentRevertDecision: boolean;
};

export const ProAgentTakeDecisionMutationDocument = gql`
  mutation ProAgentTakeDecisionMutation($input: ProAgentTakeDecisionInput!) {
    proAgentTakeDecision(input: $input)
  }
`;
export type ProAgentTakeDecisionMutationMutationFn = Apollo.MutationFunction<
  ProAgentTakeDecisionMutationMutation,
  ProAgentTakeDecisionMutationMutationVariables
>;

/**
 * __useProAgentTakeDecisionMutationMutation__
 *
 * To run a mutation, you first call `useProAgentTakeDecisionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProAgentTakeDecisionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proAgentTakeDecisionMutationMutation, { data, loading, error }] = useProAgentTakeDecisionMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProAgentTakeDecisionMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProAgentTakeDecisionMutationMutation,
    ProAgentTakeDecisionMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ProAgentTakeDecisionMutationMutation,
    ProAgentTakeDecisionMutationMutationVariables
  >(ProAgentTakeDecisionMutationDocument, options);
}
export type ProAgentTakeDecisionMutationMutationHookResult = ReturnType<
  typeof useProAgentTakeDecisionMutationMutation
>;
export type ProAgentTakeDecisionMutationMutationResult =
  Apollo.MutationResult<ProAgentTakeDecisionMutationMutation>;
export type ProAgentTakeDecisionMutationMutationOptions = Apollo.BaseMutationOptions<
  ProAgentTakeDecisionMutationMutation,
  ProAgentTakeDecisionMutationMutationVariables
>;
export const ProAgentRevertDecisionMutationDocument = gql`
  mutation ProAgentRevertDecisionMutation($input: ProAgentRevertDecisionInput!) {
    proAgentRevertDecision(input: $input)
  }
`;
export type ProAgentRevertDecisionMutationMutationFn = Apollo.MutationFunction<
  ProAgentRevertDecisionMutationMutation,
  ProAgentRevertDecisionMutationMutationVariables
>;

/**
 * __useProAgentRevertDecisionMutationMutation__
 *
 * To run a mutation, you first call `useProAgentRevertDecisionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProAgentRevertDecisionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proAgentRevertDecisionMutationMutation, { data, loading, error }] = useProAgentRevertDecisionMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProAgentRevertDecisionMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProAgentRevertDecisionMutationMutation,
    ProAgentRevertDecisionMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ProAgentRevertDecisionMutationMutation,
    ProAgentRevertDecisionMutationMutationVariables
  >(ProAgentRevertDecisionMutationDocument, options);
}
export type ProAgentRevertDecisionMutationMutationHookResult = ReturnType<
  typeof useProAgentRevertDecisionMutationMutation
>;
export type ProAgentRevertDecisionMutationMutationResult =
  Apollo.MutationResult<ProAgentRevertDecisionMutationMutation>;
export type ProAgentRevertDecisionMutationMutationOptions = Apollo.BaseMutationOptions<
  ProAgentRevertDecisionMutationMutation,
  ProAgentRevertDecisionMutationMutationVariables
>;
