import { styled } from '@mui/material/styles';
import React from 'react';

export interface AgentCardContentProps {
  children: React.ReactNode;
}

const Root = styled('div')({
  flex: 1,
  minHeight: 0,
  // overflow: 'auto',
  scrollbarWidth: 'none',
});

const AgentCardContent: React.FC<AgentCardContentProps> = ({ children }) => <Root>{children}</Root>;

export default AgentCardContent;
