import React from 'react';

import { DotSeparator, Stack } from '../../../../components';

export interface AgentHeaderButtonsProps {
  children: React.ReactNode;
}

const AgentHeaderButtons: React.FC<AgentHeaderButtonsProps> = ({ children }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    divider={<DotSeparator />}
    spacing="small"
  >
    {children}
  </Stack>
);

export default AgentHeaderButtons;
