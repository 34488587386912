import React from 'react';

import { Slideshow } from './Slideshow';
import { type SlideshowScreenProps } from './SlideshowScreen';
import { type SlideshowSlideGroupProps } from './SlideshowSlideGroup';
import { type SlideshowState } from './slideshowState';

/**
 * Computes the navigation structure of a slideshow based on its child components.
 */
export function computeStructure(children: React.ReactNode): SlideshowState['structure'] {
  const structure: SlideshowState['structure'] = { screens: [] };

  React.Children.forEach(children, (child) => {
    if (React.isValidElement<SlideshowScreenProps>(child) && child.type === Slideshow.Screen) {
      const screen: { groups: number[] } = { groups: [] };

      React.Children.forEach(child.props.children, (grandChild) => {
        if (
          React.isValidElement<SlideshowSlideGroupProps>(grandChild) &&
          grandChild.type === Slideshow.SlideGroup
        ) {
          screen.groups.push(React.Children.count(grandChild.props.children));
        }
      });

      structure.screens.push(screen);
    }
  });

  return structure;
}

/**
 * Returns the total number of groups for the current screen.
 */
export function getTotalGroups(state: SlideshowState): number {
  const screen = state.structure.screens[state.currentScreen];
  return screen ? screen.groups.length : 0;
}

/**
 * Returns the total number of slides in the current group.
 */
export function getTotalSlides(state: SlideshowState): number {
  const group = state.structure.screens[state.currentScreen]?.groups[state.currentGroup];
  return group || 0;
}

/**
 * Updates flags indicating whether there are next or previous slides available.
 */
export function updateSlideFlags(state: SlideshowState): SlideshowState {
  const { currentSlide, currentGroup, currentScreen, structure } = state;
  const { screens } = structure;
  const totalGroups = getTotalGroups(state);
  const totalSlides = getTotalSlides(state);

  return {
    ...state,
    hasNextSlide:
      currentSlide < totalSlides - 1 ||
      currentGroup < totalGroups - 1 ||
      currentScreen < screens.length - 1,
    hasPrevSlide: currentSlide > 0 || currentGroup > 0 || currentScreen > 0,
  };
}

/**
 * Navigates to the next slide, group, or screen.
 */
export function navigateToNext(state: SlideshowState): SlideshowState {
  const totalGroups = getTotalGroups(state);
  const totalSlides = getTotalSlides(state);

  if (state.currentSlide < totalSlides - 1) {
    return updateSlideFlags({ ...state, currentSlide: state.currentSlide + 1 });
  }

  if (state.currentGroup < totalGroups - 1) {
    return updateSlideFlags({ ...state, currentGroup: state.currentGroup + 1, currentSlide: 0 });
  }

  if (state.currentScreen < state.structure.screens.length - 1) {
    return updateSlideFlags({
      ...state,
      currentScreen: state.currentScreen + 1,
      currentGroup: 0,
      currentSlide: 0,
    });
  }

  return updateSlideFlags({ ...state, currentScreen: 0, currentGroup: 0, currentSlide: 0 });
}

/**
 * Navigates to the previous slide, group, or screen.
 */
export function navigateToPrev(state: SlideshowState): SlideshowState {
  if (state.currentSlide > 0) {
    return updateSlideFlags({ ...state, currentSlide: state.currentSlide - 1 });
  }

  if (state.currentGroup > 0) {
    const prevGroup = state.currentGroup - 1;
    const prevSlides = state.structure.screens[state.currentScreen]?.groups[prevGroup] || 0;

    return updateSlideFlags({ ...state, currentGroup: prevGroup, currentSlide: prevSlides - 1 });
  }

  if (state.currentScreen > 0) {
    const prevScreen = state.currentScreen - 1;
    const prevGroups = state.structure.screens[prevScreen]?.groups || [];
    const prevGroup = prevGroups.length - 1;
    const prevSlides = prevGroups[prevGroup] || 0;

    return updateSlideFlags({
      ...state,
      currentScreen: prevScreen,
      currentGroup: prevGroup,
      currentSlide: prevSlides - 1,
    });
  }

  const lastScreen = state.structure.screens.length - 1;
  const lastGroups = state.structure.screens[lastScreen]?.groups || [];
  const lastGroup = lastGroups.length - 1;
  const lastSlide = lastGroups[lastGroup] - 1 || 0;

  return updateSlideFlags({
    ...state,
    currentScreen: lastScreen,
    currentGroup: lastGroup,
    currentSlide: lastSlide,
  });
}

/**
 * Navigates to a specific slide by index.
 */
export function navigateToSlide(state: SlideshowState, index: number): SlideshowState {
  const { screens } = state.structure;
  const screenIndex = index % screens.length;
  const screen = screens[screenIndex];
  const groupIndex = Math.floor(index / screens.length) % screen.groups.length;
  const slideIndex = Math.floor(index / screens.length / screen.groups.length);

  return updateSlideFlags({
    ...state,
    currentScreen: screenIndex,
    currentGroup: groupIndex,
    currentSlide: slideIndex,
  });
}

/**
 * Validates the current screen, group, and slide indexes against a new structure.
 * If any index is out of bounds in the new structure, it resets it to 0.
 * Returns a safe set of indexes to use with the new structure.
 */
export function getValidPosition(
  state: SlideshowState,
  structure: SlideshowState['structure'],
): Pick<SlideshowState, 'currentScreen' | 'currentGroup' | 'currentSlide'> {
  const { currentScreen, currentGroup, currentSlide } = state;

  const isValidScreen = currentScreen < structure.screens.length;
  const screen = isValidScreen ? structure.screens[currentScreen] : undefined;

  const isValidGroup = screen && currentGroup < screen.groups.length;
  const groupSlideCount = isValidGroup ? screen.groups[currentGroup] : 0;

  const isValidSlide = isValidGroup && currentSlide < groupSlideCount;

  return {
    currentScreen: isValidScreen ? currentScreen : 0,
    currentGroup: isValidGroup ? currentGroup : 0,
    currentSlide: isValidSlide ? currentSlide : 0,
  };
}
