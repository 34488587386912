import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { AilyLogo } from '../../../../../../logos';
import { Typography } from '../../../../../../Typography';

export interface MoneyBoxProps {
  title: string;
  items: [MoneyBoxItemProps] | [MoneyBoxItemProps, MoneyBoxItemProps]; // Only 1-2 items
}

export interface MoneyBoxItemProps {
  name: string;
  value: string;
  sentiment: MoneyBoxItemSentiment;
}

export type MoneyBoxItemSentiment = 'positive' | 'negative';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing['small'],
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.tokens.spacing['xxsmall'],
}));

const PillContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10, // Missing spacing token
  padding: theme.spacing(theme.tokens.spacing['xsmall'], theme.tokens.spacing['small']),
  borderRadius: theme.tokens.borderRadius['round'],
  background: theme.tokens.color['background.positive'],
}));

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.tokens.spacing['xsmall'],
}));

const sentimentColorMap: Record<MoneyBoxItemSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

export const MoneyBox: FC<MoneyBoxProps> = ({ title, items }) => (
  <Root>
    <TitleContainer>
      <AilyLogo width={20} height={14} />
      <Typography variant="body.xsmall-bold-condensed" lineClamp={1}>
        {title}
      </Typography>
    </TitleContainer>
    <PillContainer>
      {items.map((item, index) => (
        <Item key={index}>
          <Typography variant="heading.H8" color={sentimentColorMap[item.sentiment]} lineClamp={1}>
            {item.value}
          </Typography>
          <Typography variant="body.xsmall-condensed" lineClamp={1}>
            {item.name}
          </Typography>
        </Item>
      ))}
    </PillContainer>
  </Root>
);
