import { FC } from 'react';

import { DotNavigation } from '../DotNavigation';
import { useSliderCarousel } from './SliderCarouselProvider';

export interface SliderCarouselNavigationProps {
  disabled?: boolean;
}

const SliderCarouselNavigation: FC<SliderCarouselNavigationProps> = ({ disabled }) => {
  const { currentIndex, goToIndex, totalSlides } = useSliderCarousel();
  return (
    <DotNavigation
      count={totalSlides}
      index={currentIndex}
      onIndexChanged={goToIndex}
      disabled={disabled}
    />
  );
};

export default SliderCarouselNavigation;
