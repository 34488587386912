import { AgentType } from '@aily/graphql-sdk/schema';
import { isEqual } from 'lodash-es';
import React, { useMemo } from 'react';

import { SlideshowProps } from '../../../../components';
import { AgentImplementationLayout } from '../../layouts/AgentImplementationLayout';
import { AgentOptimizerLayout } from '../../layouts/AgentOptimizerLayout';
import { AgentSummarizerLayout } from '../../layouts/AgentSummarizerLayout';
import { AgentDataContextProvider } from '../AgentModal/contexts/AgentDataContext';
import { useAgentStructureContext } from '../AgentModal/contexts/AgentStructureContext';

interface AgentLayoutProps extends Omit<SlideshowProps, 'children'> {
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onDecide?: () => void;
}

export const AgentLayout: React.FC<AgentLayoutProps> = React.memo(
  ({ onAgentClose, onBackdropClose }) => {
    const { agentStructure } = useAgentStructureContext();

    const renderAgentLayout = useMemo(() => {
      if (agentStructure?.agentType === AgentType.ImplementationRoad) {
        return (
          <AgentImplementationLayout
            onAgentClose={onAgentClose}
            onBackdropClose={onBackdropClose}
          />
        );
      }

      if (agentStructure?.agentType === AgentType.Optimiser) {
        return (
          <AgentOptimizerLayout onAgentClose={onAgentClose} onBackdropClose={onBackdropClose} />
        );
      }

      if (agentStructure?.agentType === AgentType.Summariser) {
        return (
          <AgentSummarizerLayout onAgentClose={onAgentClose} onBackdropClose={onBackdropClose} />
        );
      }
    }, [agentStructure, onAgentClose, onBackdropClose]);

    return (
      <AgentDataContextProvider agentStructure={agentStructure}>
        {renderAgentLayout}
      </AgentDataContextProvider>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
