import { IProPayloadTemplateResult } from '@aily/graphql-sdk/schema';
import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';

import { useEnvironment } from '../../../providers';
import { getFullUrl } from '../../../utils';
import { AilyAgent } from '../classes';
import { WispSvg } from '../components';

type WispProps = {
  width?: number;
  height?: number;
};

interface AilyAgentContextType {
  agent: AilyAgent;
  wispSize: WispProps;
  setWispSize: (width: number, height: number) => void;
  baseAudioURL: string;
  isPopupOpen: boolean;
  setIsPopupOpen: (isPopupOpen: boolean) => void;
  popupData: IProPayloadTemplateResult[];
  setPopupData: (popupData: IProPayloadTemplateResult[]) => void;
}

const AilyAgentContext = createContext<AilyAgentContextType | undefined>(undefined);

export const AilyAgentProvider = ({ children }: { children: ReactNode }) => {
  const agentRef = useRef(AilyAgent.getInstance());
  const [wispSize, setWispSizeState] = useState<WispProps>({});
  const { API_URL } = useEnvironment();
  const baseAudioURL = getFullUrl('/api/file/get-image-file/', API_URL); // FIXME Should not be here
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState<IProPayloadTemplateResult[]>([]);

  const handleSvgRef = useCallback((svgElement: SVGSVGElement | null) => {
    if (svgElement) {
      agentRef.current.setSvgElement(svgElement, '.body__glow ellipse');
    }
  }, []);

  const setWispSize = (width: number, height: number) => {
    setWispSizeState({ width, height });
  };

  const props = {
    ...(wispSize.width && { width: wispSize.width }),
    ...(wispSize.height && { height: wispSize.height }),
  };

  return (
    <AilyAgentContext.Provider
      value={{
        agent: agentRef.current,
        setWispSize,
        wispSize,
        baseAudioURL,
        isPopupOpen,
        setIsPopupOpen,
        popupData,
        setPopupData,
      }}
    >
      <WispSvg ref={handleSvgRef} {...props} />
      {children}
    </AilyAgentContext.Provider>
  );
};

export const useAilyAgent = (): AilyAgentContextType => {
  const context = useContext(AilyAgentContext);

  if (!context) {
    throw new Error('useAilyAgent must be used within an AilyAgentProvider');
  }

  return context;
};
