import { styled } from '@mui/material/styles';
import React from 'react';

export interface SliderCarouselSlideProps {
  children?: React.ReactNode;
}

const Root = styled('div')({
  flex: '0 0 100%',
  maxWidth: '100%',
});

const SliderCarouselSlide: React.FC<SliderCarouselSlideProps> = ({ children }) => (
  <Root>{children}</Root>
);

export default SliderCarouselSlide;
