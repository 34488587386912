export class SVGAnimator {
  private svgElement: SVGSVGElement;
  private targetElement: HTMLElement | null = null;
  private currentCorner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'top-right';
  private resizeObserver: ResizeObserver | null = null;
  private animationFrameId: number | null = null;
  private lastPosition: { x: number; y: number } | null = null;

  constructor(svgElement: SVGSVGElement) {
    this.svgElement = svgElement;
    this.handleResize = this.handleResize.bind(this);
  }

  /**
   * Adds 'active' class to the SVG element to start any CSS-based animations.
   */
  public enter(): void {
    this.svgElement.classList.add('active');
  }

  /**
   * Removes 'active' class from the SVG element to end any CSS-based animations.
   */
  public exit(): void {
    this.svgElement.classList.remove('active');
  }

  /**
   * Moves the SVG element to align with the specified corner of the target HTML element.
   */
  public moveToElement(
    element: HTMLElement,
    corner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'top-right',
  ): void {
    this.targetElement = element;
    this.currentCorner = corner;

    this.updatePosition(); // Immediately update
    this.addResizeObserver(); // Handle size changes
    this.startPositionTracking(); // Handle movement
  }

  private handleResize(): void {
    this.updatePosition(true); // force update on resize
  }

  private addResizeObserver(): void {
    if ('ResizeObserver' in window) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(document.body);
      if (this.targetElement) {
        this.resizeObserver.observe(this.targetElement);
      }
    }
  }

  private removeResizeObserver(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  private startPositionTracking(): void {
    const track = () => {
      this.updatePosition();
      this.animationFrameId = requestAnimationFrame(track);
    };
    this.animationFrameId = requestAnimationFrame(track);
  }

  private stopPositionTracking(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  private updatePosition(force = false): void {
    if (!this.targetElement) return;

    const rect = this.targetElement.getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    let x = 0;
    let y = 0;

    switch (this.currentCorner) {
      case 'top-left':
        x = rect.left + scrollX;
        y = rect.top + scrollY;
        break;
      case 'top-right':
        x = rect.right + scrollX;
        y = rect.top + scrollY;
        break;
      case 'bottom-left':
        x = rect.left + scrollX;
        y = rect.bottom + scrollY;
        break;
      case 'bottom-right':
        x = rect.right + scrollX;
        y = rect.bottom + scrollY;
        break;
    }

    const finalX = x - this.svgElement.clientWidth / 2;
    const finalY = y - this.svgElement.clientHeight / 2;

    // Only apply transform if position actually changed (unless forced)
    if (
      force ||
      !this.lastPosition ||
      this.lastPosition.x !== finalX ||
      this.lastPosition.y !== finalY
    ) {
      this.svgElement.style.transform = `translate(${finalX}px, ${finalY}px)`;
      this.lastPosition = { x: finalX, y: finalY };
    }
  }

  /**
   * Cleans up all observers and tracking.
   */
  public dispose(): void {
    this.removeResizeObserver();
    this.stopPositionTracking();
    this.targetElement = null;
  }
}
