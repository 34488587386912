import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { map } from 'lodash-es';
import React from 'react';

import { Stack, Typography } from '../../../..';
import { HorizontalSeparator } from '../../../commons/utils/HorizontalSeparator';
import { IRFocusRow, IRFocusRowProps } from './internal/IRFocusRow/IRFocusRow';

export interface IRFocusTemplateProps {
  moduleName?: string;
  title: string;
  sentiment: IRFocusRowProps['sentiment'];
  sentimentTitle: string;
  rows: IRFocusRowProps[];
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.tokens.spacing.xlarge,
}));

const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const IRFocusTemplate: React.FC<IRFocusTemplateProps> = ({
  moduleName,
  title,
  sentiment,
  sentimentTitle,
  rows,
}) => {
  return (
    <Container>
      <TitleContainer>
        {moduleName && (
          <Typography variant="heading.H9" color="text.subtle">
            {moduleName}
          </Typography>
        )}
        <Typography variant="body.regular-condensed">{title}</Typography>
        <Typography
          variant="heading.H6"
          color={sentiment === 'positive' ? 'text.success' : 'text.danger'}
        >
          {sentimentTitle}
        </Typography>
      </TitleContainer>
      <Stack spacing={'medium'} divider={<HorizontalSeparator />}>
        {map(rows, (row, index) => (
          <IRFocusRow key={index} {...row} />
        ))}
      </Stack>
    </Container>
  );
};
