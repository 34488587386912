import * as T from '@aily/graphql-sdk/schema';
import React from 'react';

export interface TemplateProps {
  templateData: T.IProPayloadTemplateResult;
}

// prettier-ignore
const TemplateMap = {
  IntroTemplate: React.lazy(() => import('../../templates/IntroTemplate')),
  CSScenarioTemplate: React.lazy(() => import('../../templates/CSScenarioTemplate')),
  DecisionTemplate: React.lazy(() => import('../../templates/DecisionTemplate')),
  GenericScenarioTemplate: React.lazy(() => import('../../templates/GenericScenarioTemplate')),
  GetMoneyTemplate: React.lazy(() => import('../../templates/GetMoneyTemplate')),
  ImplementationRoadTemplate: React.lazy(() => import('../../templates/ImplementationRoadTemplate')),
  InsightsTemplate: React.lazy(() => import('../../templates/InsightsTemplate')),
  InsightsSentimentTemplate: React.lazy(() => import('../../templates/InsightsSentimentTemplate')),
  IRDecisionsTemplate: React.lazy(() => import('../../templates/IRDecisionsTemplate')),
  IRFocusTemplate: React.lazy(() => import('../../templates/IRFocusTemplate')),
  LaunchesScenarioTemplate: React.lazy(() => import('../../templates/LaunchesScenarioTemplate')),
  LeaderboardTemplate: React.lazy(() => import('../../templates/LeaderboardTemplate')),
  LeaderboardInsightsTemplate: React.lazy(() => import('../../templates/LeaderboardInsightsTemplate')),
  LeaderboardTopTemplate: React.lazy(() => import('../../templates/LeaderboardTopTemplate')),
  LeversTemplate: React.lazy(() => import('../../templates/LeversTemplate')),
  MessageInsightTemplate: React.lazy(() => import('../../templates/MessageInsightTemplate')),
  OutroTemplate: React.lazy(() => import('../../templates/OutroTemplate')),
  PharmaProductScenarioTemplate: React.lazy(() => import('../../templates/PharmaProductScenarioTemplate')),
  PortfolioScenarioTemplate: React.lazy(() => import('../../templates/PortfolioScenarioTemplate')),
  ProgressBarsTemplate: React.lazy(() => import('../../templates/ProgressBarsTemplate')),
  PBNumColumnTemplate: React.lazy(() => import('../../templates/PBNumColumnTemplate')),
  RecommendationsBaseTemplate: React.lazy(() => import('../../templates/RecommendationsBaseTemplate')),
  RecommendationsInsightsTemplate: React.lazy(() => import('../../templates/RecommendationsInsightsTemplate')),
  RelatedDecisionsTemplate: React.lazy(() => import('../../templates/RelatedDecisionsTemplate')),
  SummaryTemplate: React.lazy(() => import('../../templates/SummaryTemplate')),
  TrialScenarioTemplate: React.lazy(() => import('../../templates/TrialScenarioTemplate')),
  BenchmarkTemplate: React.lazy(() => import('../../templates/BenchmarkTemplate')),
};

const Template: React.FC<TemplateProps> = ({ templateData }) => {
  if (T.isProPayloadTemplateIntroResult(templateData)) {
    return <TemplateMap.IntroTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateClinicalSupplyResult(templateData)) {
    return <TemplateMap.CSScenarioTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateDecisionResult(templateData)) {
    return <TemplateMap.DecisionTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateGenericScenarioResult(templateData)) {
    return <TemplateMap.GenericScenarioTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateGetMoneyResult(templateData)) {
    return <TemplateMap.GetMoneyTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateImpRoadResult(templateData)) {
    return <TemplateMap.ImplementationRoadTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateInsightsResult(templateData)) {
    return <TemplateMap.InsightsTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateInsightsSentimentResult(templateData)) {
    return <TemplateMap.InsightsSentimentTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateIrDecisionsResult(templateData)) {
    return <TemplateMap.IRDecisionsTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateIrFocusResult(templateData)) {
    return <TemplateMap.IRFocusTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateLaunchesResult(templateData)) {
    return <TemplateMap.LaunchesScenarioTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateLeaderboardResult(templateData)) {
    return <TemplateMap.LeaderboardTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateLeaderboardInsightsResult(templateData)) {
    return <TemplateMap.LeaderboardInsightsTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateLeaderboardTopResult(templateData)) {
    return <TemplateMap.LeaderboardTopTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateLeversResult(templateData)) {
    return <TemplateMap.LeversTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateMessageInsightResult(templateData)) {
    return <TemplateMap.MessageInsightTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateOutroResult(templateData)) {
    return <TemplateMap.OutroTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplatePharmaProductResult(templateData)) {
    return <TemplateMap.PharmaProductScenarioTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplatePortfolioResult(templateData)) {
    return <TemplateMap.PortfolioScenarioTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateProgressBarsResult(templateData)) {
    return <TemplateMap.ProgressBarsTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateProgressBarsColumnResult(templateData)) {
    return <TemplateMap.PBNumColumnTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateRecommendationsBaseResult(templateData)) {
    return <TemplateMap.RecommendationsBaseTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateRecommendationsInsightsResult(templateData)) {
    return (
      <TemplateMap.RecommendationsInsightsTemplate key={templateData.id} data={templateData} />
    );
  }

  if (T.isProPayloadTemplateRelatedDecisionsResult(templateData)) {
    return <TemplateMap.RelatedDecisionsTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateSummaryResult(templateData)) {
    return <TemplateMap.SummaryTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateTrialsResult(templateData)) {
    return <TemplateMap.TrialScenarioTemplate key={templateData.id} data={templateData} />;
  }

  if (T.isProPayloadTemplateBenchmarkResult(templateData)) {
    return <TemplateMap.BenchmarkTemplate key={templateData.id} data={templateData} />;
  }

  return null;
};

export default Template;
