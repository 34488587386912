const ArrowLeftIcon = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
    <path
      d="M7.17187 13.4561L0.82218 6.99955L7.17188 0.542969"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowLeftIcon;
